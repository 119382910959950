import React from 'react';
//import { CircularProgress } from '@mui/material';
import gif from '../assets/piearm-animated-brain.gif';
import { Stack, Typography, Box, useMediaQuery } from '@mui/material';

const LoadingSpinner = ({ size, text="Please wait while your content is being created" }) => {
  const mobile = useMediaQuery('(max-width: 600px)');
  return (
    <Stack alignItems="center">
      { mobile ? 
      <Box width="320px" px={2}>
        <Typography textAlign="center">{text}</Typography>
      </Box> 
      :
      <Typography textAlign="center">{text}</Typography>
      }
      <img src={gif} alt="loading..." />
    </Stack>
      //<CircularProgress size={size} disableShrink/>

  );
};

export default LoadingSpinner;
