import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from 'react-ga4';

const GA_MEASUREMENT_ID = 'G-ET073PXJVV';


const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!GA_MEASUREMENT_ID) return;
    Analytics.initialize(GA_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    // Track page views
    Analytics.send('page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  return null;
};

export default GoogleAnalytics;
