import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
//import TextField from '../modules/components/TextField';
import instagram from '../assets/insta.svg';
import facebook from '../assets/fb.svg';
import linkedin from '../assets/linkedin.webp';
import twitter from '../assets/x.svg';
import tiktok from '../assets/tiktok.svg';
import footer from '../assets/footer.svg';
import { useMediaQuery } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

export default function Footer({mode}) {
  const mobile = useMediaQuery('(max-width:700px)');
  const curYear = new Date().getFullYear();
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', pt: 1, pb: 10, bgcolor:'background.default', pl: 4 }}
    >
      <Stack bgcolor={mode === 'light' ? 'primary.main' : 'primary.dark'} direction={mobile ? "column" : "row"} spacing='5vw' width="100%" py={2} alignItems={"start"} borderRadius={'100px 0 0 100px'} px={mobile ? 5 : 0}>
          <Box/>
          {!mobile && <Stack direction="row" height="100%" alignItems="center">
            <img src={footer} alt="logo" width={mobile ? "100px" : "186px"} height={mobile ? "100px" : "186px"}/>
          </Stack> }
          <Stack pl={mobile ? 0 : "2vw"}>
            <Typography variant={mobile ? "h5" : "h3"} fontWeight={700} marked="left" gutterBottom color="white">
            LEARN
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} href="https://learn.piearm.ai/about/" >About</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/features/" >Features</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/demos/">Demos</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                 href="https://learn.piearm.ai/use-cases/">Use Cases</Link>
              </Box>
            </Box>
          </Stack>
          <Stack item xs={6} sm={4} md={2}>
            <Typography variant={mobile ? "h5" : "h3"} fontWeight={700} marked="left" gutterBottom color="white">
              LEGAL
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/user-agreement/">User Agreement</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/service-provider-terms/">Service Provider Terms</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/privacy-policy/">Privacy Policy</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{color: 'white', fontSize: mobile ? 16 : 20}} target="_blank"
                href="https://learn.piearm.ai/community-guidelines/">Community Guidelines</Link>
              </Box>
            </Box>
          </Stack>
          <Stack spacing='4vh' py={2} pl={mobile ? 0 : "4vw"}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box width='36px' mr={1} component={ReactRouterLink} to="https://x.com/PIEARMAI" target='_blank'>
                <img src={twitter} alt="X" width="100%" height="auto"/>
              </Box>
              <Box width='36px' mr={1} component={ReactRouterLink} to="https://www.instagram.com/piearm.ai/" target='_blank'>
                <img src={instagram} alt="Instagram" width="100%" height="auto"/>
              </Box>
              <Box width='36px' mr={1} component={ReactRouterLink} to="https://www.facebook.com/piearmai" target='_blank'>
                <img src={facebook} alt="Facebook" width="100%" height="auto"/>
              </Box>
              <Box width='36px' component={ReactRouterLink} to="https://www.tiktok.com/@piearm.ai" target='_blank'>
                <img src={tiktok} alt="Tiktok" width="100%" height="auto"/>
              </Box>
              <Box width='36px' component={ReactRouterLink} to="https://www.linkedin.com/showcase/piearm-ai/" target='_blank'>
                <img src={linkedin} alt="LinkedIn" width="100%" height="auto"/>
              </Box>
            </Stack>
            <Stack spacing={1} width="250px">
                <Typography color="white" variant={mobile ? "h6" : "h5"}>The AI Marketing Mastermind™</Typography>
                <Typography color="white" variant={"h6"}>© PIEARM 2023 - {curYear+1}</Typography>
            </Stack>
          </Stack>
        </Stack>
    </Typography>
  );
}