import React, {useEffect} from 'react' ;
import { Box, Button, Stack, Typography, Stepper, Step, StepLabel} from '@mui/material';
import './style.css';
import { useMediaQuery} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useTheme} from '@mui/material';
import pricingImage from '../assets/pricing.svg';
import step1 from '../assets/step1.svg';
import step2 from '../assets/step2.svg';
import step3 from '../assets/step3.svg';
import { Helmet } from 'react-helmet-async';

const otherBlue = '#4069B2';

const steps = [
    {title: "Go to Dashboard", content: "Choose tools from keyword research to digital strategy to SEO copywriting, ads, and social media planning."},
    {title: "Add the Details", content: "Provide the relevant information you need in the fields provided so PIEARM can capture the context."},
    {title: "Click Generate", content: "Within seconds your request is analyzed, processed, generated and can be extended and edited as needed."}
]


const HomeScreen = ({mode}) => { 
    const navigate = useNavigate();
    const theme = useTheme();
    const loggedIn = localStorage.getItem("authToken");
    const mobile = useMediaQuery("(max-width: 800px)");
    const small = useMediaQuery("(max-width: 600px)");

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = "https://fast.wistia.com/embed/medias/1egs2iiudz.jsonp";
        script1.async = true;
    
        const script2 = document.createElement('script');
        script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;
    
        document.body.appendChild(script1);
        document.body.appendChild(script2);
    
        // Clean up the scripts on unmount
        return () => {
          document.body.removeChild(script1);
          document.body.removeChild(script2);
        };
      }, []);

    return (
        <Box sx={{letterSpacing: '0.5px'}}>
            <Helmet>
                <title>PIEARM™</title>
                <meta name="description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds."/>
            </Helmet>
            <div >
                <div class="body-container">
                    <div class="hero-left hero-column">
                        <div class="inner-wrap">
                        <div class="hero-heading">
                            <h3 style={{color: otherBlue}}>AI Marketing Tools</h3>
                            <h2>Work Smarter +</h2>
                            <div class="hero-subhead" style={{color: theme.palette.primary.main}}><span style={{color: otherBlue, fontSize: '50px'}}>30x Faster</span> with PIEARM</div>
                        </div>
                        
                        <div class="hero-content">
                            <p>Minimize how you plan, implement, execute, analyze, report and measure marketing tasks.</p>
                        </div>
                        <Button onClick={loggedIn ? ()=> navigate("/dashboard") : () => navigate("/register")} variant="contained" disableElevation={true}
                        sx={{borderRadius: 10, fontSize: 20, width: 240, py:0.8, fontWeight: 700, letterSpacing: 0.5}}>
                            Maximize Results
                        </Button>
                        </div>
                    </div>

                    <div class="hero-right hero-column" style={{paddingTop: mobile ? '60px' : 0}}>
                    <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                        <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                        <div 
                            className="wistia_embed wistia_async_1egs2iiudz seo=true videoFoam=true" 
                            style={{ height: '100%', position: 'relative', width: '100%', borderRadius: '40px', overflow: 'hidden' }}  // Added borderRadius and overflow: hidden
                            >
                            <div 
                                className="wistia_swatch" 
                                style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%', borderRadius: '10px' }}  // Added borderRadius here as well
                            >
                                <img 
                                src="https://fast.wistia.com/embed/medias/1egs2iiudz/swatch" 
                                style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%', borderRadius: '10px' }}  // Added borderRadius to the image
                                alt="" 
                                aria-hidden="true" 
                                onLoad={(e) => { e.target.parentNode.style.opacity = 1; }} 
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" mt="10vh" px={mobile ? 3 : 0}>
                {!mobile && <img src={pricingImage} alt="pricing" style={{ height: '78vh' }} />}
                <Box pb={5}>
                    <Typography fontSize={mobile ? 40 : 60} fontWeight="bold" textAlign="center" mt={5} color="primary">How It Works</Typography>
                    <Stack direction="column" spacing={4} position="relative">
                        {/* Vertical Line */}
                        <Box 
                            sx={{
                                position: 'absolute',
                                left: small ? '20px' : '25px', // Adjust this value based on image width and spacing
                                top: small ? '120px' : '100px', // Start the line where the first image starts
                                width: '2px', 
                                height: `65%`, // Adjust the height to fit the content
                                backgroundColor: '#C9E8F8', 
                            }}
                        />
                        {steps.map((step, index) => (
                            <Stack key={index} direction="row" alignItems="center" spacing={3} sx={{ zIndex: 0 }}>
                                <img src={index === 0 ? step1 : index === 1 ? step2 : step3} alt={"step_"+index} style={{height: small ? '40px' : '50px'}}/>
                                <Box 
                                    sx={{borderRadius: 20, border: "2px solid #C9E8F8", pl: mobile ? 4 : 5, pr: mobile ? 2 : 4, py: 3, maxWidth: 600, minWidth: 260}}>
                                    <Typography variant={small ? "h6" : "h5"} fontWeight="bold" color={otherBlue}>{step.title}</Typography>
                                    <Typography fontSize={small ? 15 : 18}>{step.content}</Typography>
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            </Stack>

            <Box pt="2vh" pb={15} display="flex" justifyContent="center">
            <Button onClick={loggedIn ? ()=> navigate("/dashboard") : () => navigate("/register")} variant="contained" disableElevation={true}
                        sx={{fontSize: 20, width: 280, py:0.8, fontWeight: 700, letterSpacing: 0.5, borderRadius: 10}}>
                            Get Your Free Access
            </Button>   
            </Box>
            
        </Box>
        
    )
}

export default HomeScreen;