import React from 'react' ;
import { Box, Stack, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';
import logo from '../assets/footer.svg';

//import Cookies from 'js-cookie';

const ErrorScreen = () => { 
    return (
        <Stack py="18vh" direction="row" px="5vw" spacing={'8vw'}>
            <Box>
                <Typography variant="h1" fontWeight={700}>We’re sorry. We can’t find the page you’re looking for.</Typography>
                <Typography variant="h2" fontWeight={700} mt={2}>Please navigate to your 
                    <ReactRouterLink to="/dashboard"> dashboard</ReactRouterLink> or <ReactRouterLink to="/login">sign in</ReactRouterLink>
                </Typography>
            </Box>
            <img src={logo} alt="logo" style={{width:"50vw", height: "auto"}}/>
        </Stack>
    )
}

export default ErrorScreen;