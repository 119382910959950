import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Card, Collapse, Alert, TextField, Button, CircularProgress,
    Divider, Slider, Tooltip, Stack, InputAdornment, ClickAwayListener, IconButton, } from '@mui/material';
import { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import ThemedButton from '../common/ThemedButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { SocketContext }  from '../../SocketContext';
import Tone from '../common/Tone';
//import LangSelect from '../common/LangSelect';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ShareModal from '../common/ShareModal';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import HearingIcon from '@mui/icons-material/Hearing';
import SaveIcon from '@mui/icons-material/Save';
import MicIcon from '@mui/icons-material/Mic';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import SaveModal from '../common/SaveModal';
import { htmlToText } from 'html-to-text';
import LoadingSpinner from '../common/LoadingSpinner';
import { Helmet } from 'react-helmet-async';
import './style.css';
import { set } from 'mongoose';

const promote_title = "Add a short title or message designed to educate, promote, inspire or critique..";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center"
    },
}));

const AdCopyScreen = () => { 
    const socket = useContext(SocketContext);
    const quillRef1 = useRef(null);
    const quillRef2 = useRef(null);
    const quillRef3 = useRef(null);
    const quillRef4 = useRef(null);
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [content, setContent] = useState("");
    const [error, setError] = useState("");
    const [gen1, setGen1] = useState("");
    const [gen2, setGen2] = useState("");
    const [gen3, setGen3] = useState("");
    const [gen4, setGen4] = useState("");
    const [curOutput, setCurOutput] = useState(1);
    const [outputs, setOutputs] = useState(1);
    const [genArray, setGenArray] = useState([]);
    const [open, setOpen] = useState(false);
    const [finished, setFinished] = useState(false);
    const [tone, setTone] = useState("professional");
    const [language, setLanguage] = useState("English");
    const [htmlContent, setHtmlContent] = useState('');
    const [shareOpen, setShareOpen] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [btnText, setBtnText] = useState("Generate");
    const [audioUrl, setAudioUrl] = useState(null);
    const [saveOpen, setSaveOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [generated, setGenerated] = useState("");
    const handleSaveClose = () => setSaveOpen(false);
    const handleShareClose = () => setShareOpen(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setGenerated(gen1+"\n\n"+gen2+"\n\n"+gen3+"\n\n"+gen4);
    }, [gen1, gen2, gen3, gen4]);

    useEffect(() => {
        let cur = 1;

        // Listen for the 'message' event
        socket.on('adCopyPipe', (data) => {
            //every time the previous contains '<br/>~<br/>', replace with '' and update curOutput += 1
            if (data.message.includes("<br/>~<br/>")) {
                cur += 1;
            }
            if (cur === 1) {
                setGen1(prev => prev.replace(/```html/g, "").replace(/```/g, "").replace(/<br\/>~<br\/>/g, "") + data.message);
            } else if (cur === 2) {
                setGen2(prev => prev.replace(/```html/g, "").replace(/```/g, "").replace(/<br\/>~<br\/>/g, "") + data.message);
            }
            else if (cur === 3) {
                setGen3(prev => prev.replace(/```html/g, "").replace(/```/g, "").replace(/<br\/>~<br\/>/g, "") + data.message);
            }
            else if (cur === 4) {
                setGen4(prev => prev.replace(/```html/g, "").replace(/```/g, "").replace(/<br\/>~<br\/>/g, "") + data.message);
            }
        });
    
        // Clean up the effect by removing the listener
        return () => socket.off('adCopyPipe');
      }, [socket]);

    const adCopyHandler = async (e) => { 
        e.preventDefault();

        try {
            setFinished(false);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                setBtnText("Regenerate");
                /*if (genArray.length > 0) {
                    setGenArray([]);
                }*/
                if (gen1.length > 0) {
                    setGen1("");
                }
                if (gen2.length > 0) {
                    setGen2("");
                }
                if (gen3.length > 0) {
                    setGen3("");
                }
                if (gen4.length > 0) {
                    setGen4("");
                }
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.post("/api/auth/set-socket-id", { socketId: socket.id }, config);
                const { data } = await axios.post("/api/ai/ad-copy", {content, outputs, tone, language}, config);
                if (data) {
                    setFinished(true);
                    if (outputs > 1) {
                        const regex = /Version \d+:/g;
                        const result = data.split(regex).filter((str) => str !== "");
                        setGenArray(result);
                    }
                }
                
            } else {
                setFinished(true);
                setError("You are not authorized to use this feature. Please login.");
                setTimeout(() => {
                    setError("");
                }, 5000);
                return;
            }
        } catch (err) {
            setFinished(true);
            console.log("An error occurred: ", err.message);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    const handleCopyClick = async (quillRef) => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const clipboardItem = new ClipboardItem({
                "text/plain": new Blob(
                    [quill.root.innerHTML],
                    { type: "text/plain" }
                ),
                "text/html": new Blob(
                    [quill.root.outerHTML],
                    { type: "text/html" }
                ),
            });
            
            navigator.clipboard.write([clipboardItem]);
        }
    };
    
    const handleShare = async () => {
        try {

            const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    let artId = '';
                    if (!articleId) {
                        const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : gen1+"\n\n"+gen2+"\n\n"+gen3+"\n\n"+gen4}, config)
                        if (data) {
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    } else {
                        const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : gen1+"\n\n"+gen2+"\n\n"+gen3+"\n\n"+gen4, id: articleId}, config)
                        if (data) {
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    }
                    const titleData = await axios.post("/api/ai/create-title", {generated}, config)
                    if (titleData.data.title) {
                        await axios.post("/api/article/share", {id: artId, title: titleData.data.title}, config);
                        setTitle(titleData.data.title);
                    }
                    setShareOpen(true);
                }
        } catch (err) {
            setShareOpen(true);
            setError("You are not authorized to use this feature. Please login.");
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    const handleSave = async () => {
        try {

            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                let articleIdData;
                if (!articleId) {
                    const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : gen1+"\n\n"+gen2+"\n\n"+gen3+"\n\n"+gen4, public: false}, config)
                    if (data) {
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                } else {
                    //console.log(htmlContent)
                    const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : gen1+"\n\n"+gen2+"\n\n"+gen3+"\n\n"+gen4, id: articleId, public: false}, config)
                    if (data) {
                        //console.log(data)
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                }
                // save article to user
                await axios.post("/api/auth/save-article", {articleId: articleIdData}, config);
                setSaveOpen(true);
            }
        } catch (err) {
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    // Function to handle changes in the editor and update the state
    const handleEditorChange = (content, delta, source, editor) => {
        setHtmlContent(editor.getHTML());
    };

    const [listenLoading, setListenLoading] = useState(false);

    const handleListen = async () => {
        try {
            setListenLoading(true);

            let listenText = htmlToText(gen1);
            if (gen2.length > 0)
                listenText += "\nVersion 2:\n" + htmlToText(gen2);
            if (gen3.length > 0)
                listenText += "\nVersion 3:\n" + htmlToText(gen3);
            if (gen4.length > 0)
                listenText += "\nVersion 4:\n" + htmlToText(gen4);
            
            listenText = listenText.replace(/\*/g, "-");
            //replace all lines that dont end with a period or colon with a colon
            const {data} = await axios.post("/api/tts", { text: listenText }, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',  // Set content type to JSON if you're sending JSON data
                },
            },
            );
            // Convert audioData (which is an ArrayBuffer) into a blob
            const audioBlob = new Blob([data], { type: "audio/wav" });
            
            // Convert the blob into an object URL
            setAudioUrl(window.URL.createObjectURL(audioBlob));
            setListenLoading(false);
        } catch (err) {
            console.log(err.message);
            setListenLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    const addAudioElement = async (blob, field) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        
        try {
            const response = await axios.post('/api/stt', formData);
            setContent(response.data)
        } catch (err) {
            console.error("There was an error uploading the audio:", err);
        }
    };

    const { startRecording, stopRecording, recordingBlob, isRecording} = useAudioRecorder();
    
    useEffect(() => {
        if (!recordingBlob) return;
        addAudioElement(recordingBlob, 'content')
    // recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recordingBlob]);

    //usEffect for generated
    useEffect(() => {
        if (gen1.length > 0) {
            setHtmlContent(gen1);
            const quill = quillRef1?.current?.getEditor();
            const delta = quill?.clipboard?.convert(gen1);
            quill?.setContents(delta);
        }
        if (gen2.length > 0) {
            setHtmlContent(gen1+gen2);
            const quill = quillRef2?.current?.getEditor();
            const delta = quill?.clipboard?.convert(gen2);
            quill?.setContents(delta);
        }
        if (gen3.length > 0) {
            setHtmlContent(gen1+gen2+gen3);
            const quill = quillRef3?.current?.getEditor();
            const delta = quill?.clipboard?.convert(gen3);
            quill?.setContents(delta);
        }
        if (gen4.length > 0) {
            setHtmlContent(gen1+gen2+gen3+gen4);
            const quill = quillRef4?.current?.getEditor();
            const delta = quill?.clipboard?.convert(gen4);
            quill?.setContents(delta);
        }
    }, [gen1, gen2, gen3, gen4]);

    return (
        <Box width={isNotMobile ? "50%" : "90%" } 
            p="2rem" 
            m="2rem auto"
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? 15 : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Helmet>
                <title>Ad Copy | PIEARM™</title>
                <meta name="description" content="Create compelling ad content that drives results." />
                <meta property="og:title" content={`Ad Copy | PIEARM™`} />
                <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                <meta property="og:description" content="Create compelling ad content that drives results" />
                <meta property="og:url" content="https://piearm.ai/ad-copy" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Ad Copy | PIEARM™`} />
                <meta name="twitter:description" content="Create compelling ad content that drives results" />
                <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
            </Helmet>
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={adCopyHandler}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center" mb={3} color="primary">Ad Copy Generator</Typography>

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>What would you like to promote?</Typography> 
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <LightTooltip
                    PopperProps={{
                    disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    placement="top-end"
                    open={open}
                    disableFocusListener
                    disableTouchListener
                    title={promote_title}
                    >
                        <TextField sx={{".MuiOutlinedInput-root": { borderRadius: 10, fontSize: 16 }, mb:isNotMobile ? 4 : 2}} 
                        required placeholder={isNotMobile ? "Create ad copy promoting seasonal sales for your skincare brand." : "My skin care brand..."} fullWidth multiline  value={content} onChange={(e) => {setContent(e.target.value); handleTooltipClose();}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Stack direction="row" spacing={.5} alignItems="center">
                                    {!isRecording && <IconButton onClick={startRecording} 
                                    sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                        <MicIcon sx={{color: "background.alt", fontSize: 16}}/>
                                    </IconButton>
                                    }
                                    {isRecording && <IconButton onClick={stopRecording} 
                                    sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                        <StopRoundedIcon sx={{color: "background.alt", fontSize: 16}}/>
                                    </IconButton>
                                    }
                                
                                    <IconButton onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen}>
                                        <HelpIcon sx={{color: "primary.main"}}/>
                                    </IconButton>
                                </Stack>
                            </InputAdornment>
                            ),
                        }}
                        />
                    </LightTooltip>
                </ClickAwayListener>

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} >Number of Outputs </Typography>
                <Tooltip title={!localStorage.getItem("sub") ? "Purchase a paid plan to access this feature" : ""} followCursor disabled={true}>
                    <Box p={1} mb={1} width="180px">
                        <Slider
                            aria-label="Outputs"
                            defaultValue={1}
                            valueLabelDisplay="auto"
                            size={isNotMobile ? "medium" : "small"}
                            step={1}
                            min={1}
                            max={4}
                            marks={[{value: 1, label: "1"}, {value: 2, label: "2"}, {value: 3, label: "3"}, {value: 4, label: "4"}]}
                            onChange={(e, value) => setOutputs(value)}
                            disabled = {!localStorage.getItem("sub")}
                        />
                    </Box>
                </Tooltip>

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500}>Tone</Typography> 
                <Tone tone={tone} setTone={setTone}/>

                {/*<Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500}>Language</Typography> 
                <LangSelect language={language} setLanguage={setLanguage}/>*/}

                <ThemedButton w="100%" type="submit" text={btnText} fSize={isNotMobile ? 16 : 14} pad={isNotMobile ? 1.4 : 0.8} bool={tone.length === 0 || language.length === 0 || (!finished && gen1.length !== 0)}/>
                
                <Divider sx={{my: isNotMobile ? 4: 2}}></Divider>

            </form>

            {gen1 &&
            <Box width="100%">
                <Typography variant={isNotMobile ? "h4": "h5"} fontWeight="bold"> Ad Copy Mockup </Typography>
                { gen1.length > 0 &&
                    <>
                    <Typography variant={isNotMobile ? "h5": "h6"} fontWeight="bold" mb={2}> Version 1:</Typography>
                    <ReactQuill ref={quillRef1} theme="snow"  onChange={handleEditorChange}></ReactQuill>
                    <Button variant="outlined" onClick={()=>handleCopyClick(quillRef1)} startIcon={<ContentCopyRoundedIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2, mt :1}}>
                        Copy Text
                    </Button>
                    </>
                }
                { gen2.length > 0 &&
                    <>
                    <Typography variant={isNotMobile ? "h5": "h6"} fontWeight="bold" mt={2}> Version 2:</Typography>
                    <ReactQuill ref={quillRef2} theme="snow"  onChange={handleEditorChange}></ReactQuill>
                    <Button variant="outlined" onClick={()=>handleCopyClick(quillRef2)} startIcon={<ContentCopyRoundedIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2, mt :1}}>
                        Copy Text
                    </Button>
                    </>
                }
                { gen3.length > 0 &&
                    <>
                    <Typography variant={isNotMobile ? "h5": "h6"} fontWeight="bold" mt={2}> Version 3:</Typography>
                    <ReactQuill ref={quillRef3} theme="snow"  onChange={handleEditorChange}></ReactQuill>
                    <Button variant="outlined" onClick={()=>handleCopyClick(quillRef3)} startIcon={<ContentCopyRoundedIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2, mt :1}}>
                        Copy Text
                    </Button>
                    </>
                }
                { gen4.length > 0 &&
                    <>
                    <Typography variant={isNotMobile ? "h5": "h6"} fontWeight="bold" mt={2}> Version 4:</Typography>
                    <ReactQuill ref={quillRef4} theme="snow"  onChange={handleEditorChange}></ReactQuill>
                    <Button variant="outlined" onClick={()=>handleCopyClick(quillRef4)} startIcon={<ContentCopyRoundedIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2, mt :1}}>
                        Copy Text
                    </Button>
                    </>
                }
                {finished && 
                <Stack direction={isNotMobile ? "row" : "column"} alignItems="center"spacing={2} my={2}>
                    <Button variant="outlined" onClick={()=>handleListen()} startIcon={listenLoading ? <CircularProgress size={14}/> : <HearingIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}} disabled={listenLoading}>
                        Listen
                    </Button>
                    <Button variant="contained" startIcon={<SaveIcon/>} 
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                    onClick={()=>handleSave()}>
                        Save
                    </Button>
                    <Button variant="outlined" size="medium" onClick={handleShare}
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                            startIcon={ <ShareRoundedIcon sx={{color: "primary.main"}}/> }
                    >
                        Share
                    </Button>
                    <Button variant="contained" onClick={() => { window.scrollTo({top: 0, behavior: "smooth"});}}
                        sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, borderRadius: 1, width: 140}}
                        startIcon={ <KeyboardDoubleArrowUpRoundedIcon/> }
                    > 
                        Back to top
                    </Button>
                    <ShareModal
                        open={shareOpen}
                        title={title}
                        content={htmlContent ? htmlContent : gen1+gen2+gen3+gen4}
                        handleClose={handleShareClose}
                        mobile={!isNotMobile}
                        id={articleId}
                        kw={content}
                    />
                    <SaveModal
                        open={saveOpen}
                        content={htmlContent ? htmlContent : gen1}
                        handleClose={handleSaveClose}
                        mobile={!isNotMobile}
                        id={articleId}
                    />
                </Stack>
                }
                {listenLoading && 
                    <Stack>
                        <LoadingSpinner text="Please wait a moment while your audio file processes."/>
                    </Stack>
                }
                {audioUrl && <audio controls src={audioUrl}></audio>}
            </Box> }
        </Box>
    )
}

export default AdCopyScreen;