import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery, Typography, Divider, Box, Stack, Card, Avatar,
    Collapse, Alert, CircularProgress, Button } from '@mui/material';
import HearingIcon from '@mui/icons-material/Hearing';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios'
import ShareIcons from '../common/ShareIcons';
import LoadingSpinner from '../common/LoadingSpinner';
import { htmlToText } from 'html-to-text';
import SaveModal from '../common/SaveModal';
import logo from '../assets/logo512.png';
import Markdown from "react-markdown";
import PersonIcon from '@mui/icons-material/Person';
import { Helmet } from 'react-helmet-async';

function ShareScreen({mode}) {
    const {title} = useParams();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [content, setContent] = useState(false);
    const [audioUrl, setAudioUrl] = useState("");
    const [listenLoading, setListenLoading] = useState(false);
    const [error, setError] = useState("");
    const [saveOpen, setSaveOpen] = useState(false);
    const handleSaveClose = () => setSaveOpen(false);
    const [loading, setLoading] = useState(false);
    const [systemMessages, setSystemMessages] = useState([]);
    const [userMessages, setUserMessages] = useState([]);
    const [articleId, setArticleId] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //useEffect to fetch article
    useEffect(() => {
        axios.get(`/api/article/title/${title}`)
        .then(async response => {
            setContent(response.data.content);
            setArticleId(response.data._id);
        })
        .catch(error => {
            console.log(error);
        })
    }, []);


    useEffect(() => {
        async function fetchHistories() {
            try {
                const { data } = await axios.get(`/api/history/get-title/${title}`);
                if (data.history) {
                    const regex = /\*-\*/;
                    const arr = data.history.split(regex);
                    const arr1 = [];
                    const arr2 = [];
                    for (let i = 0; i < arr.length; i++) {
                        if (i % 2 === 0) {
                            arr1.push(arr[i]); // add even-indexed items to arr1
                        } else {
                            arr2.push(arr[i]); // add odd-indexed items to arr2
                        }
                    }
                    setUserMessages(arr1.filter(element => element !== ''));
                    setSystemMessages(arr2.filter(element => element !== ''));
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchHistories();
    }, [title]);

    const handleSave = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                // save article to user
                if (userMessages && userMessages.length > 0) {
                    const { data } = await axios.get(`/api/history/get-title/${title}`);
                    let content = "";
                    for (let i = 0; i < userMessages.length; i++) {
                        content += userMessages[i]
                        content += "*-*"
                        content += systemMessages[i]
                        content += "*-*"
                    }
                    await axios.post("/api/history/create", { type: "chat", content: content, threadId: data.threadId }, config);
                } else {
                    await axios.post("/api/auth/save-article", {articleId: articleId}, config);
                }
                setSaveOpen(true);
            } else {
                setError("Please login to save the article.");
            }
        } catch (err) {
            setError("Error saving article.")
        }
    };

  
    const handleListen = async () => {
        try {
            let listenText = htmlToText(content);
            listenText = listenText.replace(/\*/g, "-");
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                setListenLoading(true);

                const {data} = await axios.post("/api/tts", { text: listenText }, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',  // Set content type to JSON if you're sending JSON data
                    },
                },
                );
                // Convert audioData (which is an ArrayBuffer) into a blob
                const audioBlob = new Blob([data], { type: "audio/wav" });
                
                // Convert the blob into an object URL
                setAudioUrl(window.URL.createObjectURL(audioBlob));
            } else {
                setError("Please login to listen to this content.");
            }
            
            
        } catch (err) {
            console.log(err.message);
            setListenLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        } finally {
            setListenLoading(false);
        }
    };

    function replaceDashesAndCapitalize(str) {
        return str.replace(/-/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    const getOgDescription = (htmlContent) => {
        // Convert HTML to plain text
        const text = htmlToText(htmlContent, {
          wordwrap: false,
          // Additional options can be set here
        });
      
        // Extract the first sentence using a simple regex
        const firstSentenceMatch = text.match(/[^\.!\?]+[\.!\?]+/);
        const firstSentence = firstSentenceMatch ? firstSentenceMatch[0] : text;
      
        // Truncate to 140 characters
        const truncated = firstSentence.length > 140
          ? firstSentence.substring(0, 137) + '...'
          : firstSentence;
      
        return truncated;
    };
      

  return (
        <Box width={isNotMobile ? "70%" : "90%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "22vh" : 25}
            border="1px solid black"
            borderRadius={5} 
            backgroundColor={mode === "light" ? "background.alt" : "#d8dcf2"}
            sx={{boxShadow: 3}}
        >
            <Helmet>
                <title> {replaceDashesAndCapitalize(title)} | PIEARM™ </title>
                <meta name="description" content="Shared PIEARM™ article" />
                <meta property="og:title" content={`${replaceDashesAndCapitalize(title)} | PIEARM™`} />
                <meta property="og:description" content={content ? getOgDescription(content): getOgDescription(userMessages[0])} />
                <meta property="og:url" content={`https://piearm.ai/share/${title}`} />
                <meta property="og:type" content="website" />
                <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${replaceDashesAndCapitalize(title)} | PIEARM™`} />
                <meta name="twitter:description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds." />
                <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
            </Helmet>
                {/*<pre style={{ whiteSpace: "pre-wrap" }}>
                    <Typography fontSize={isNotMobile ? 16 : 14} dangerouslySetInnerHTML={{__html:  content}}></Typography>
                </pre>*/}
                {(userMessages && userMessages.length > 0 && title &&
                    <Box width="100%" mb={isNotMobile ? 3 : 2}>
                        <Typography variant={isNotMobile ? "h4" : "h5"} fontWeight="bold"> Message History </Typography>
                        {userMessages.map((message, index) => (
                            <Stack spacing={2} mt={2} key={index}>
                                <Stack direction="row" py={0} spacing={2} alignItems="center">
                                    <Card sx={{ py: 0, px: 2, boxShadow: mode === 'light' ? "2px 3px 5px 0px #ececec" : "2px 3px 5px 0px #393f4a", border: mode === 'light' ? "3px solid #C9E8F8" : "3px solid #4068B2", borderRadius: 10, bgcolor: mode === 'light' ? "#eff4f8" : '#535f7d', width: "100%" }}>
                                        <pre style={{ whiteSpace: "pre-wrap" }}><Typography fontSize={isNotMobile ? 16 : 14 }>{userMessages[index]}</Typography></pre>
                                    </Card>
                                    <Avatar alt="Chat Assistant" sx={{ bgcolor: "#eef5ff", width: 48, height: 48 }}>
                                        <PersonIcon sx={{ color: "primary.main", fontSize: 25 }} />
                                    </Avatar>
                                </Stack>
                                <Stack direction="row" py={0} spacing={2} alignItems="center">
                                    <Avatar alt="Chat Assistant" src={logo} sx={{ bgcolor: "#eef5ff", width: 48, height: 48, p: 1 }} />
                                    <Card sx={{ py: 0, px: 2, border: 1, boxShadow: mode === 'light' ? "2px 3px 5px 0px #ececec" : "2px 3px 5px 0px #393f4a", border: mode === 'light' ? "3px solid #C9E8F8" : "3px solid #4068B2", borderRadius: 10, bgcolor: mode === 'light' ? "#fff" : "neutral.light", width: "100%" }}>
                                        {loading && index === 0 ?  <pre style={{ whiteSpace: "pre-wrap" }}><Typography>Loading...</Typography></pre>
                                        : 
                                        (systemMessages[systemMessages.length - index - 1].includes('https') && systemMessages[systemMessages.length - index - 1].includes('imgur') ? 
                                            <pre style={{ whiteSpace: "pre-wrap" }}><img src={systemMessages[systemMessages.length - index - 1]} style={{width:'33vw'}} /></pre>
                                            :  <div style={{minHeight: '48px', alignContent: "center"}}>
                                            <Markdown>{systemMessages[systemMessages.length - index - 1]}</Markdown>
                                        </div> )
                                        }
                                    </Card>
                                </Stack>
                            </Stack>
                        ))}
                    </Box>
                    )}
                <div  dangerouslySetInnerHTML={{__html:  content}}/>
                <Divider sx={{my: 2, bgcolor: "grey"}}/>
                <Collapse in={error.length > 0}>
                    <Alert severity="error" sx={{mb:2}}>{error}</Alert>
                </Collapse>
                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                    {!userMessages && <Button variant="outlined" onClick={()=>handleListen()} startIcon={listenLoading ? <CircularProgress size={14}/> : <HearingIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14}} disabled={listenLoading}>
                        Listen
                    </Button>}
                    <Button variant="contained" startIcon={<SaveIcon/>} 
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14}}
                    onClick={handleSave}
                    >
                        Save
                    </Button>
                </Stack>
                {listenLoading && 
                    <Stack>
                        <LoadingSpinner text="Please wait a moment while your audio file processes."/>
                    </Stack>
                }
                {audioUrl && <audio controls src={audioUrl}></audio>}
                <Typography fontWeight="bold" mt={1} color="black">
                Share this article:
                </Typography>
                <ShareIcons id={articleId} title={title} />
                <SaveModal
                    open={saveOpen}
                    content={content}
                    handleClose={handleSaveClose}
                    mobile={!isNotMobile}
                    id={articleId}
                />
        </Box>
  );
}

export default ShareScreen;
