import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, Collapse, Alert, TextField, Chip, Button, Divider, Stack, Avatar, IconButton, InputAdornment } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import logo from '../assets/logo512.png';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SocketContext } from '../../SocketContext';
import DOMPurify from 'isomorphic-dompurify';
import Markdown from "react-markdown";
import { marked } from 'marked';
import ShareModal from '../common/ShareModal';
import SaveModal from '../common/SaveModal';
import { Helmet } from 'react-helmet-async';
import './style.css'

const ChatAssistantScreen = ({mode}) => {
    const socket = React.useContext(SocketContext);
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 800px)");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [generated, setGenerated] = useState("");
    const [question, setQuestion] = useState("");
    const [systemMessages, setSystemMessages] = useState([]);
    const [userMessages, setUserMessages] = useState([]);
    const [id, setId] = useState("id");
    const [histories, setHistories] = useState([]);
    const [deleted, setDeleted] = useState("");
    const [htmlContent, setHtmlContent] = useState('');
    const [assistantId, setAssistantId] = useState("");
    const [threadId, setThreadId] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [shareOpen, setShareOpen] = useState(false);
    const [saveOpen, setSaveOpen] = useState(false);
    const [authConfig, setAuthConfig] = useState({});
    const [messageId, setMessageId] = useState("");
    const [title, setTitle] = useState("");
    const [historyLoading, setHistoryLoading] = useState(false);
    
    const messagesContainerRef = useRef(null);

    const handleShareClose = () => setShareOpen(false);
    const handleSaveClose = () => setSaveOpen(false);

    const scrollToBottom = () => {
        messagesContainerRef.current?.scrollTo({
            top: messagesContainerRef.current.scrollHeight,
            behavior: "smooth",
        });
    };

    const handleFileUpload = async (event) => {
        setLoading(true);
        const selectedFiles = Array.from(event.target.files);
      
        const formData = new FormData();
      
        selectedFiles.forEach((file) => {
          formData.append('files', file);
        });
      
        try {
          const token = await axios.get("/api/auth/refresh-token");
          if (token.data && token.data !== "Unauthorized") {
            const config = { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token.data}` } };
            const response = await axios.post('/api/chat/upload-file-to-vs', formData, config);
      
            //console.log('Files uploaded successfully:', response.data);
            
            // Create a map of filenames to OpenAI file IDs
            const fileMap = new Map(response.data.filesData.map(fileData => [fileData.originalName, fileData.fileId]));
      
            // Update the state to pair each file with its corresponding OpenAI fileId
            const updatedFiles = selectedFiles.map(file => ({
              file,
              fileId: fileMap.get(file.name)
            }));
      
            // Add the uploaded files with their IDs to the state
            setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...updatedFiles]);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          if (error?.response?.data?.error) {
            setError(error.response.data.error);
         } else if (error?.response?.data.message) {
            setError(error.response.data.message);
          } else {
            setError("Error uploading files");
          }
        }
    };
    
    const handleDelete = async (fileToDelete) => {
        try {
            if (fileToDelete.fileId.includes('imgur.com')) {
                setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileToDelete.name));
                return;
            }
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
              const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
              const response = await axios.post('/api/chat/delete-file', {fileId: fileToDelete.fileId}, config);
                if (response.data.success) {
                    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileToDelete.name));
                } else {
                    setError("Error deleting file");
                }
            }
        } catch (error) {
            setError("Error deleting file");
            console.error('Error deleting files:', error);
        }
        // Delete the file from OpenAI
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function fetchHistories() {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    const response = await axios.get('/api/history/get-histories/chat', config);
                    //get assistant
                    const {data} = await axios.get('/api/auth/assistant', config);
                    if (data?.assistantId) {
                        setAssistantId(data.assistantId);
                    }
                    else {
                        //create assistant
                        const assistant = await axios.post('/api/chat/create-assistant', {}, config);
                        setAssistantId(assistant.data.assistantId);
                    }
                    if (!threadId) {
                        const res = await axios.post(`/api/chat/create-thread`, {}, config);
                        //console.log('threadId', res.data)
                        setThreadId(res?.data?.threadId);
                    }
                    setHistories(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchHistories();
    }, []);

    useEffect(() => {
        async function fetchHistories() {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    setAuthConfig({ headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } });
                    const { data } = await axios.get(`/api/history/get/${id}`, config);
                    if (data.history) {
                        const regex = /\*-\*/;
                        const arr = data.history.split(regex);
                        const arr1 = [];
                        const arr2 = [];
                        for (let i = 0; i < arr.length; i++) {
                            if (i % 2 === 0) {
                                arr1.push(arr[i]); // add even-indexed items to arr1
                            } else {
                                arr2.push(arr[i]); // add odd-indexed items to arr2
                            }
                        }
                        setUserMessages(arr1.filter(element => element !== ''));
                        setSystemMessages(arr2.filter(element => element !== ''));
                    }
                    if (data.threadId) {
                        setThreadId(data.threadId);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchHistories();
    }, [id, deleted]);

    useEffect(() => {
        // Listen for the 'message' event
        socket.on('chatPipe', (data) => {
            //console.log(data);
            setSystemMessages(prev => [...prev.slice(0, -1), prev[prev.length - 1] + data.message]);
            setGenerated(prev => prev?.replace(/```html/g, "")?.replace(/```/g, "") + data.message);
            setMessageId(data.messageId);
            setQuestion("");
        });

        // Clean up the effect by removing the listener
        return () => {
            socket.off('chatPipe');
        }
    }, [socket]);

    const chatHandler = async (e) => {
        e.preventDefault();
    
        try {
            setLoading(true);
            
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                setAuthConfig({ headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } });
                //create an array called images that contains the url of images (fileIds)
                const images = [];
                uploadedFiles.forEach(file => {
                    if (file.fileId.includes('imgur.com'))
                        images.push({filename: file.file.name, url: file.fileId});
                });
    
                const requestData = {
                    content: question,
                    threadId,
                    images: images // Include base64 encoded images in the payload
                };
    
                setUserMessages([...userMessages, question]);
                setSystemMessages([...systemMessages, '']);
                // Scroll to the bottom after a message is submitted
                setTimeout(() => {
                    scrollToBottom();
                }, 500);
    
                const { data } = await axios.post("/api/chat/message", requestData, config);
                setLoading(false);
                if (data) {
                    setSystemMessages([...systemMessages, data]);
                } else {
                    setUserMessages(userMessages.slice(0, -1));
                }
                setGenerated(data);
                setQuestion("");
                setLoading(false);

            } else {
                setLoading(false);
                setError("You are not authorized to use this feature. Please login.");
                return;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err?.response?.data?.error) {
                setError(err.response.data.error);
            } else if (err?.response?.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    }
    
    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
            setLoading(false);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    const historyHandler = async (share=false) => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                setHistoryLoading(true);

                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                setAuthConfig({ headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } });
                const { data } = await axios.get(`/api/history/get/${id}`, config);
                let content = "";

                for (let i = 0; i < userMessages.length; i++) {
                    content += userMessages[i]
                    content += "*-*"
                    content += systemMessages[i]
                    content += "*-*"
                }
                if (data) {
                    //console.log('saving thread:', threadId)
                    const response = await axios.put("/api/history/append", { content: content, id: id, threadId: threadId }, config);
                    setTitle(response?.data?.title);
                    if (share)
                        await axios.post("/api/history/share", {id: id, title: response?.data?.title}, config); //create a useffect that sets title of hsitory already exists
                    //console.log(response.data)
                } else {
                    const titleData = await axios.post("/api/ai/create-history-title", {history: content}, config)
                    if (titleData?.data?.title) {
                        setTitle(titleData.data.title);
                    }
                    const response = await axios.post("/api/history/create", { type: "chat", content: content, threadId: threadId, title: titleData?.data?.title }, config);
                    //console.log(response.data)
                    setId(response.data._id)

                    if (share) {
                        await axios.post("/api/history/share", {id: response.data._id, title: titleData?.data?.title}, config);
                    }
                    return response.data._id;
                }
                return id;
            } else {
                setError("You are not authorized to use this feature. Please login.");
                return;
            }
        } catch (err) {
            setHistoryLoading(false);
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        } finally {
            setHistoryLoading(false);
        }
    }

    const deleteHistoryElement = async (id) => {
        try {
            setLoading(true);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.delete(`/api/history/${id}`, config);
                setDeleted(id);
                setHistories((prevHistories) => prevHistories.filter((history) => history._id !== id));  // Update histories state to remove deleted history
                setLoading(false);
            } else {
                setLoading(false);
                setError("You are not authorized to use this feature. Please login.");
                return;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    };

    const quillRef = useRef(null);

    const handleEditorChange = (content, delta, source, editor) => {
        setHtmlContent(editor.getHTML());
    };

    const handleCopy = (text) => {
        const htmlText = DOMPurify.sanitize(marked.parse(text));
        const clipboardItem = new ClipboardItem({
            "text/plain": new Blob(
                [htmlText],
                { type: "text/plain" }
            ),
            "text/html": new Blob(
                [htmlText],
                { type: "text/html" }
            ),
        });
        navigator.clipboard.write([clipboardItem]);
    };

    const handleShare = async () => {
        try {
            await historyHandler(true);
            setLoading(false);
            setShareOpen(true);
        } catch (err) {
            setError("You are not authorized to use this feature. Please login.");
        }
    };

    const handleSave = async () => {
        try {
            await historyHandler();
            setSaveOpen(true);
        } catch (err) {
            setError("Error saving chat. Please try again later.");
        }
    };

    const clearChat = async () => {
        try {
            const res = await axios.post(`/api/chat/create-thread`, {}, authConfig);
            //console.log('threadId', res.data)
            setThreadId(res?.data?.threadId);
            setUserMessages([]);
            setSystemMessages([]);
            setGenerated("");
            setId("id")
        } catch (err) {
            console.error(err);
            setError('Error clearing chat');
        }
    }
        


    return (
        <Stack direction={isNotMobile ? "row" : "column" } spacing='2vw' backgroundColor={theme.palette.background.alt} mt={isNotMobile ? 13.5 : 11} mb={'10vh'} px='2vw'>
            <Helmet>
                <title>Ask PIEARM | PIEARM™</title>
                <meta name="description" content="Ask PIEARM: Your Chat Assistant" />
                <meta property="og:title" content={`Ask PIEARM | PIEARM™`} />
                <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                <meta property="og:description" content="sk PIEARM: Your Chat Assistant" />
                <meta property="og:url" content="https://piearm.ai/ask-piearm" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Ask PIEARM | PIEARM™`} />
                <meta name="twitter:description" content="sk PIEARM: Your Chat Assistant" />
                <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
            </Helmet>
            <Box width={isNotMobile ? 300 : '100%'} alignItems="center" spacing={isNotMobile ? 0 : 1}> {/* History Dropdown */}
                <Stack direction={isNotMobile ? "column" : "row"} alignItems="center" spacing={isNotMobile ? 0 : 1.5} display="flex" justifyContent="center" px={2}>
                    <Typography variant={"h6"} fontWeight="700" textAlign="center" mb={isNotMobile ? 2 : 0} color="primary" sx={{whiteSpace: 'nowrap',}}>Ask PIEARM</Typography>

                    <FormControl sx={{width: '100%', maxWidth: isNotMobile ? 230 : 300}}>
                        <InputLabel id="demo-simple-select-label">History</InputLabel>
                        <Select sx={{borderRadius: 10}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={id}
                            label="History"
                            onChange={(event) => setId(event.target.value)}
                            size={isNotMobile ? "medium" : "small"}
                        >
                            {histories && histories.map(history => (
                                <MenuItem key={history._id} value={history._id}>
                                    {history.history.split("*-*")[0].substring(0, 50)}...
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();  // Prevents the history from loading
                                            deleteHistoryElement(history._id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                {userMessages.length > 0 && <Stack mt={isNotMobile ? 2 : 1} spacing={1} direction={isNotMobile ? "column" : "row"}>
                    {!isNotMobile && <Box flex={1} />}
                    <Button variant="outlined" onClick={handleSave} fullWidth={true}
                        sx={{ fontWeight: "bold", textTransform: 'none', fontSize: isNotMobile ? 16 : 14, borderRadius: 10, maxWidth: isNotMobile ? 'inherit' : 150 }}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                    <Button variant="outlined" size="medium" onClick={handleShare} fullWidth={true}
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: isNotMobile ? 16 : 14, borderRadius: 10, maxWidth: isNotMobile ? 'inherit' : 150}}
                            startIcon={ <ShareRoundedIcon sx={{color: "primary.main"}}/> }
                    >
                        Share
                    </Button>
                    {!isNotMobile && <Box flex={1} />}
                </Stack>}
            </Box>
            <Divider orientation={isNotMobile ? "vertical" : "horizontal"} flexItem={true}></Divider>
            <Box px='6vw' width='100%'>
                <Collapse in={error.length > 0}>
                    <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
                </Collapse>

                {((generated || id) &&
                <Box width="100%" mb={isNotMobile ? 3 : 2} >
                    <Stack
                        ref={messagesContainerRef}  // Attach the ref here
                        spacing={2}
                        mt={2}
                        sx={{ height: isNotMobile ? '60vh' : 288, overflowY: 'auto', pb: isNotMobile ? 5 : 2 }}
                    >
                        {
                            userMessages.slice().reverse().map((message, index) => (
                                <div key={index}>
                                    {/* User Message */}
                                    <Stack direction="row" py={0} spacing={isNotMobile ? 2 : 1} alignItems="center">
                                        <Card sx={{ py: 0, px: 2, boxShadow: mode === 'light' ? "2px 3px 5px 0px #ececec" : "2px 3px 5px 0px #393f4a", border: mode === 'light' ? "3px solid #C9E8F8" : "3px solid #4068B2", borderRadius: 10, bgcolor: mode === 'light' ? "#eff4f8" : '#535f7d', width: "100%" }}>
                                            <pre style={{ whiteSpace: "pre-wrap" }}><Typography fontSize={isNotMobile ? 16 : 14 }>{userMessages[index]}</Typography></pre>
                                        </Card>
                                        <Avatar alt="Chat Assistant" sx={{ bgcolor: mode === 'light' ? "#eef5ff" : '#eef5ff', width: 48, height: 48 }}>
                                            <PersonIcon sx={{ color: mode === 'light' ? "primary.main" : 'primary.dark', fontSize: 25 }} />
                                        </Avatar>
                                        <IconButton onClick={() => handleCopy(userMessages[index])}>
                                            <ContentCopyIcon sx={{fontSize: isNotMobile ? 'inherit' : 18}}/>
                                        </IconButton>
                                    </Stack>

                                    {/* Corresponding System Message */}
                                    <Stack direction="row" mt={2} py={0} spacing={isNotMobile ? 2 : 1} alignItems="center">
                                        <Avatar alt="Chat Assistant" src={logo} sx={{ bgcolor: "#eef5ff", width: 48, height: 48, p: 1 }} />
                                        <Card sx={{ py: 0, px: 2, border: 1, boxShadow: mode === 'light' ? "2px 3px 5px 0px #ececec" : "2px 3px 5px 0px #393f4a", border: mode === 'light' ? "3px solid #C9E8F8" : "3px solid #4068B2", borderRadius: 10, bgcolor: mode === 'light' ? "#fff" : "neutral.light", width: "100%" }}>
                                            {loading && index === systemMessages.length-1 ? 
                                                <pre style={{ whiteSpace: "pre-wrap", fontSize: isNotMobile ? 16 : 14}}><Typography>Loading...</Typography></pre>
                                                : 
                                                (systemMessages[index].includes('https') && systemMessages[index].includes('imgur') ? 
                                                    <pre style={{ whiteSpace: "pre-wrap" }}><img src={systemMessages[index]} style={{ width: '40vw' }} /></pre>
                                                    : 
                                                    <div style={{ minHeight: '48px', alignContent: "center", fontSize: isNotMobile ? 16 : 14  }}>
                                                        <Markdown>{systemMessages[index]}</Markdown>
                                                    </div>
                                                )
                                            }
                                        </Card>
                                        <IconButton onClick={() => handleCopy(systemMessages[index])}>
                                            <ContentCopyIcon sx={{fontSize: isNotMobile ? 'inherit' : 18}}/>
                                        </IconButton>
                                    </Stack>
                                </div>
                            ))
                        }
                    </Stack>
                </Box>
                )}
                <Divider sx={{ my: isNotMobile ? 3 : 2 }}></Divider>
                <form>
                    {isNotMobile ? <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>Ask PIEARM a question or tell PIEARM what you need</Typography> : 
                    <Typography fontSize={16} fontWeight={500} mb={0.5}>Ask PIEARM a question</Typography>
                    }
                    <Stack direction="row" spacing={isNotMobile ? 2 : 1} alignItems="center" mb={1.5}>
                        <TextField
                            sx={{
                                ".MuiOutlinedInput-root": { borderRadius: 10},
                            }}
                            size={isNotMobile ? "medium" : "small"}
                            required
                            placeholder={isNotMobile ? "Ask me anything!" : "Ask..."}
                            fullWidth
                            multiline
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            sx={{ wordBreak: 'none', minWidth: 'auto', padding: 0, marginRight: 1, borderRadius: 10, p: 0.5 }}
                                        >
                                            <UploadFileIcon />
                                            <input
                                                type="file"
                                                hidden
                                                multiple
                                                accept="image/*,text/x-c,text/x-csharp,text/x-c++,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/html,text/x-java,application/json,text/markdown,application/pdf,text/x-php,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/x-python,text/x-script.python,text/x-ruby,text/x-tex,xstext/plain,text/css,text/javascript,application/x-sh,application/typescript"
                                                onChange={handleFileUpload}
                                            />
                                        </Button>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            size="small"
                                            component="label"
                                            onClick={chatHandler}
                                            variant="contained"
                                            type="submit" // Replace with your submit handler
                                            disabled={loading}
                                            disableElevation={true}
                                            sx={{borderRadius: 10}}
                                        >
                                            Submit
                                        </Button>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {userMessages && userMessages.length > 0 &&
                            <Button variant="outlined"  onClick={() => clearChat()}
                                sx={{ fontWeight: "bold", textTransform: 'none', fontSize: 14, borderRadius: 2, width: isNotMobile ? 84 : 80}}
                                startIcon={<DeleteForeverIcon />} 
                            >
                                Clear
                            </Button>
                        }
                    </Stack>
                    {/*Display upload files as chips*/}
                    {uploadedFiles.length > 0 && (
                        <Stack direction="row" spacing={1}>
                        {uploadedFiles.map((file, index) => (
                            <Chip
                            key={index}
                            label={file?.file?.name}
                            onDelete={() => handleDelete(file)}
                            />
                        ))}
                        </Stack>
                    )}
                </form>
                {generated &&
                    <Box mt={4}>
                        <ReactQuill ref={quillRef} theme="snow" onChange={handleEditorChange}></ReactQuill>
                    </Box>
                }
                {generated && <Box height='4vh' />}
            </Box>
            <ShareModal
                open={shareOpen}
                content={htmlContent ? htmlContent : generated}
                handleClose={handleShareClose}
                mobile={!isNotMobile}
                id={id}
                kw={'PIEARM.AI'}
                title={title}
            />
            <SaveModal
                open={saveOpen}
                content={htmlContent ? htmlContent : generated}
                handleClose={handleSaveClose}
                mobile={!isNotMobile}
                id={id}
                kw={'PIEARM.AI'}
            />
        </Stack>
    )
}

export default ChatAssistantScreen;
