export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#F3F5FF",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#3d486b",//"#4D4D4D",
    700: "#383f57",
    800: "#4c5470",//"#383f57",
    900: "#283148",//"#293047",
    1000: "#000000",
  },
  primary: {
    50: "#a4c0f8",
    100: "#7ca7f7",
    200: "#649af7",
    300: "#C9E8F8",
    400: "#277af7",
    500: "#1E3B6E",//"#0067f7",
    600: "#0255d1",
    700: "#1b325e",//"#0346b2",
    800: "#043995",
    900: "#05307e",
  },
};

export const themeSettings = (mode) => {
  return {
    palette: {
      mode,
      primary: {
        dark: mode === 'light' ? colorTokens.primary[700] : colorTokens.primary[500],
        main: mode === 'light' ? colorTokens.primary[500] : colorTokens.primary[300],
        light: colorTokens.primary[300],
      },
      neutral: {
        dark: colorTokens.grey[700],
        main: colorTokens.grey[500],
        mediumMain: colorTokens.grey[400],
        medium: colorTokens.grey[200],
        light: mode === 'light' ? colorTokens.grey[50] : colorTokens.grey[700],
      },
      background: {
        default: mode === 'light' ? '#fff' : colorTokens.grey[900],
        alt: mode === 'light' ? colorTokens.grey[0] : colorTokens.grey[600],//colorTokens.grey[800],
      },
      text: {
        primary: mode === 'dark' ? '#FFFFFF' : '#000000',
        secondary: mode === 'dark' ? colorTokens.grey[500] : colorTokens.grey[700],
      }
    },
    typography: {
      fontFamily: [
        'Lato',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 14,
      letterSpacing: "0.5px",
      h1: {
        fontSize: 44,
        letterSpacing: "0.5px",
      },
      h2: {
        fontSize: 36,
        letterSpacing: "0.5px",
      },
      h3: {
        fontSize: 28,
        letterSpacing: "0.5px",
      },
      h4: {
        fontSize: 24,
        letterSpacing: "0.5px",
      },
      h5: {
        fontSize: 20,
        letterSpacing: "0.5px",
      },
      h6: {
        fontSize: 18,
        letterSpacing: "0.5px",
      },
      h7: {
        fontSize: 16,
        letterSpacing: "0.5px",
      },
    },
  };
};
