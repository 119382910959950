import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, Collapse, Alert, useTheme, Divider, TextField, Chip, InputAdornment, Button } from '@mui/material';
import ThemedButton from '../common/ThemedButton';
import axios from 'axios';
import './PricingScreen.css';

const btnText = "Submit";

const UserRequestScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleEmailAdd = (e) => {
    e.preventDefault();
    const emailDomain = emailInput.split('@')[1];
    if (!emailDomain) {
      setError("Invalid email format");
      return;
    }

    if (emails.length === 0) {
      setDomain(emailDomain);
    }

    if (emailDomain !== domain && emails.length > 0) {
      setError(`All emails must be from the same domain: @${domain}`);
      return;
    }

    if (emails.length < 5 && emailInput && !emails.includes(emailInput)) {
      setEmails([...emails, emailInput]);
      setEmailInput("");
      setError(""); // Clear error if the email is successfully added
    }
  };

  const handleEmailDelete = (emailToDelete) => () => {
    setEmails((emails) => emails.filter((email) => email !== emailToDelete));
    if (emails.length === 1) {
      setDomain("");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (emails.length === 0) {
        setError("Please add at least one email");
        return;
      }
      const token = await axios.get("/api/auth/refresh-token");
      if (token.data && token.data !== "Unauthorized") {
        const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
        await axios.post("/api/enterprise/user-request", { emails }, config);
        setSuccess("Request submitted successfully!");
      } else {
        setError("Unauthorized");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.error) {
        setError(err.response.data.error);
      } else if (err.message) {
        setError(err.message);
      }
    } finally {
        setLoading(false);
        setEmails([]);
        setEmailInput("");
    }
  };

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 5000);
    }
  }, [success]);

  return (
    <Box
      width={isNotMobile ? "50%" : "90%"}
      p="2rem"
      m="2rem auto"
      mt={isNotMobile ? 18 : 14}
      mb={isNotMobile ? 15 : 18}
      borderRadius={5}
      backgroundColor={theme.palette.background.alt}
      sx={{ boxShadow: 5 }}
    >
      <Collapse in={error.length > 0}>
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      </Collapse>

      <form onSubmit={submitHandler}>
        <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center">Users Request</Typography>
        <Divider sx={{ mt: 1, mb: 2 }}></Divider>

        <TextField
          variant="outlined"
          disabled={emails.length >= 5 || error.length > 0}
          label="Enter Email"
          value={emailInput}
          onChange={handleEmailChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEmailAdd(e);
            }
          }}
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={handleEmailAdd} variant="outlined">
                  Add Email
                </Button>
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
          {emails.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={handleEmailDelete(email)}
              color="primary"
            />
          ))}
        </Box>

        <ThemedButton w="100%" type="submit" text={btnText} fSize={isNotMobile ? 16 : 14} pad={isNotMobile ? 1.4 : 0.8} disabled={loading}/>

        <Collapse in={success.length > 0}>
          <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>
        </Collapse>
      </form>
    </Box>
  );
};

export default UserRequestScreen;
