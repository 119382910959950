import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Typography, CardContent, useMediaQuery, Card, Grid, Tooltip, Button, Stack } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import pricingImage from '../assets/pricing.svg';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const ItemType = 'CARD';
const otherBlue = '#4069B2'

const defaultOrder = [
    ["Ask PIEARM", "Make PIEARM your chat assistant.", "ask-piearm"],
    ["Market Research", "Gather detailed information on your target market.", "market-research"],
    ["Keyword Research", "Generate SEO keyword research reports with ease.", "keyword-research"], 
    ["Digital Strategy", "Create a digital strategy for a brand, company, product, or service.", "digital-strategy"],
    ["Content Strategy", "Create a media strategy to grow your business.", "content-strategy"],
    ["Article Generator", "Create high-quality content that provides in-depth information on any topic that you desire.", "seo"],
    ["Press Release Generator", "Craft captivating press releases with ease.", "pr-copy"],
    ["Ad Copy Generator", "Create compelling ad content that drives results.", "ad-copy"],
    ["Social Media Plan Generator", "Receive a comprehensive social media strategy.", "social-media-plan"],
    ["Social Media Copy Generator", "Create compelling social media copy.", "social-media-copy"],
    ["Social Media Growth", "Grow your socials with tailored strategies.", "social-growth"],
    ["Marketing Email Copy Generator", "Craft conversion-driven emails effortlessly.", "marketing-email"],
    ["Newsletter Copy Generator", "Create content that keeps subscribers engaged.", "newsletter-copy"],
    ["Product or Service Copy Generator", "Create webpage descriptions that showcase your offerings.", "product-service-description"],
    ["Podcast Episode Generator", "Create an engaging podcast script.", "podcast"],
    ["Funding Plan Generator", "Craft a comprehensive plan to raise capital.", "funding-plan"],
    ["Analytics and Reporting", "Custom Google Analytics KPIs integration and reports.", "enterprise"],
    ["AI Lead Generation", "Collects leads and visitor data.", "enterprise"],
];

const Dashboard = ({ mode }) => {
    const [cards, setCards] = useState(defaultOrder);

    useEffect(() => {
        function arraysAreEquivalent(arr1, arr2) {
            // Sort both arrays by their first elements
            const sortedArr1 = [...arr1].sort((a, b) => a[0].localeCompare(b[0]));
            const sortedArr2 = [...arr2].sort((a, b) => a[0].localeCompare(b[0]));
            
            // Check if lengths are different
            if (sortedArr1.length !== sortedArr2.length) {
                return false;
            }
            
            // Check each element for equality
            for (let i = 0; i < sortedArr1.length; i++) {
                if (sortedArr1[i][0] !== sortedArr2[i][0] ||
                    sortedArr1[i][1] !== sortedArr2[i][1] ||
                    sortedArr1[i][2] !== sortedArr2[i][2]) {
                    return false;
                }
            }
            
            // Arrays are equivalent
            return true;
        }
        const fetchData = async () => {
            try {
                const tokenResponse = await axios.get("/api/auth/refresh-token");
                const token = tokenResponse.data;

                if (token && token !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
                    const dashboardResponse = await axios.get("/api/auth/dashboard", config);
                    const data = dashboardResponse.data;

                    if (data?.length === defaultOrder.length && Array.isArray(data) && arraysAreEquivalent(data, defaultOrder)) {
                        setCards(data);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const updateData = async () => {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    await axios.post("/api/auth/update-dashboard", { dashboard: cards }, config);
                }
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    }, [cards]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) => {
            const updatedCards = [...prevCards];
            const [draggedCard] = updatedCards.splice(dragIndex, 1);
            updatedCards.splice(hoverIndex, 0, draggedCard);
            return updatedCards;
        });
    }, []);

    const mobile = useMediaQuery("(max-width: 600px)");
    const lower = useMediaQuery("(max-width: 900px)");
    const upper = useMediaQuery("(max-width: 1300px)");

    const boxStyle = {
        flexGrow: 1,
        margin: 'auto',
        maxWidth: 1500,
        py: 15,
        px: "5vw",
        mb: "15vh"
    };

    const cardStyle = {
        height: 230,
        boxShadow: mode === 'light' ? "2px 3px 5px 0px #ececec" : "2px 3px 5px 0px #393f4a",
        border: mode === 'light' ? "3px solid #C9E8F8" : "3px solid #4068B2",
        flexGrow: 1,
        flexBasis: 0,
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': { cursor: 'pointer' },
        bgcolor: mode === 'light' ? "white" : '#1E3B6E',
        borderRadius: 10,
        px: 2,
    };

    const CardItem = ({ card, index, moveCard }) => {
        const ref = useRef(null);
        const [, drop] = useDrop({
            accept: ItemType,
            hover(item, monitor) {
                if (!ref.current) return;
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) return;

                const hoverBoundingRect = ref.current.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                moveCard(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });
        const [{ isDragging }, drag] = useDrag({
            type: ItemType,
            item: { type: ItemType, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        const opacity = isDragging ? 0 : 1;
        drag(drop(ref));
        return (
            <Grid ref={ref} item xs={12} sm={6} md={4} lg={3} style={{ opacity }} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Drag me!" placement="top" arrow>
                    <Card sx={{ ...cardStyle, position: 'relative' }}>
                        <Box component={ReactRouterLink} to={`/${card[2]}`} sx={{ color: "black" }}>
                            <Typography variant="h4" fontWeight={700} color={mode === 'light' ? 'primary.main' : 'white'}textAlign="center" sx={{mt:3}}>{card[0]}</Typography>
                            <CardContent sx={{ py: 1.2, px: 2 }}>
                                <Typography sx={{letterSpacing: '0.5px', mt: 2}} color={mode === 'light' ? 'black' : 'white'} fontSize={!lower && upper ? 14 : (mobile ? 15 : 16)} textAlign="center">
                                    {card[1]}
                                </Typography>
                            </CardContent>
                            <Button
                                component={ReactRouterLink}
                                to={`/${card[2]}`}
                                variant="contained"
                                disableElevation
                                sx={{
                                    py: 0.4,
                                    fontSize: 16,
                                    borderRadius: 10,
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    position: 'absolute',
                                    bottom: '12px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 100,
                                }}
                            >
                                Access
                            </Button>
                        </Box>
                    </Card>
                </Tooltip>
            </Grid>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={boxStyle}>
                <Helmet>
                    <title>Dashboard | PIEARM™</title>
                    <meta name="description" content="Dashboard for PIEARM's marketing tools." />
                    <meta property="og:title" content={`Dashboard | PIEARM™`} />
                    <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                    <meta property="og:description" content="Dashboard for PIEARM's marketing tools." />
                    <meta property="og:url" content="https://piearm.ai/dsashboard" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`Dashboard | PIEARM™`} />
                    <meta name="twitter:description" content="Dashboard for PIEARM's marketing tools." />
                    <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
                </Helmet>
                <Stack direction="row" alignItems="center" spacing={2} mb={6}>
                    <Box flex={1} />
                    {!mobile && <img src={pricingImage} alt="pricing" style={{ height: lower ?  340 : 380 }} />}
                    <Box width={500}>               
                        <Typography fontSize={lower ? (mobile ? 40 : 48) : 52} fontWeight={650} mb={1} color={mode === 'light' ? "primary.main" : otherBlue}>Marketing Tools</Typography>
                        <Typography variant={mobile ? "h5" : "h3"} mb={1} fontWeight={300} color={mode === 'light' ? 'inherit' : 'primary.light'}>Drag and drop tools to reorder your dashboard</Typography>
                    </Box>
                    <Box flex={1} />
                </Stack>
                
                <Grid container spacing={3} justifyContent="center">
                    {cards.map((card, index) => (
                        <CardItem key={index} index={index} card={card} moveCard={moveCard} />
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                    <Button variant="contained" onClick={() => setCards(defaultOrder)} size="large"  disableElevation={true}
                    sx={{width: mobile ? '50vw' : '30vw', borderRadius: 10, fontWeight: 700}}>Reset Order</Button>
                </Box>
            </Box>
        </DndProvider>
    );
};

export default Dashboard;
