import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, Collapse, Alert, useTheme, Divider, TextField, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import './PricingScreen.css';
import ThemedButton from '../common/ThemedButton';
import axios from 'axios';
const btnText = "Submit";

const EnterpriseScreen = ({mode}) => {


    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const [type, setType] = useState('');
    const [request, setRequest] = useState('');

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (!type || !request) {
                setError("Please fill out all fields");
                return;
            }
            const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    await axios.post("/api/enterprise/feature-request", {type, request}, config);
                    setSuccess("Request submitted successfully!");
                    setType('');
                    setRequest('');
                } else {
                    setError("Unauthorized");
                }
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.message) {
                setError(err.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }
    //useEffect for error message
    useEffect(() => {
        if (error) {
            //scroll to top
            window.scrollTo(0, 0);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    //useEffect for success message
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess("");
            }, 5000);
        }
    }
    , [success]);




  return (
    <Box width={isNotMobile ? "50%" : "90%" } 
            p="2rem" 
            m="2rem auto"
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? 15 : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error.length > 0}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            
            <form onSubmit={submitHandler}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center" color="primary" mb={3}>Advanced Feature Request</Typography>

                <FormControl fullWidth>
                    <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>Type</Typography>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={type}
                        onChange={handleChange}
                        sx={{borderRadius: 10}}
                    >
                        <MenuItem value={'Analytics and Reporting'}>Analytics and Reporting</MenuItem>
                        <MenuItem value={'AI Lead Generation'}>AI Lead Generation</MenuItem>
                    </Select>
                </FormControl>

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mt={2} mb={isNotMobile ? 1 : 0.5}>Please list specific details about your feature request</Typography> 
                
                <TextField required minRows={10} multiline rows={10} fullWidth value={request} sx={{mb: 2, ".MuiOutlinedInput-root": { borderRadius: 10}}}
                onChange={(e) => setRequest(e.target.value)}></TextField>


                {/*<Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500}>Language</Typography> 
                <LangSelect language={language} setLanguage={setLanguage}/>*/}

                <ThemedButton w="100%" type="submit" text={btnText} fSize={isNotMobile ? 16 : 14} pad={isNotMobile ? 1.4 : 0.8}/>
                <Collapse in={success.length > 0}>
                    <Alert severity="success" sx={{mb:2}}>{success}</Alert>
                </Collapse>
            </form>

        </Box>
  );
};

export default EnterpriseScreen;
