import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery, CircularProgress, ClickAwayListener, IconButton,
    Collapse, Alert, TextField, Button, Divider, Stack, Paper, Tooltip, InputAdornment, Card, CardContent, Pagination } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import MicIcon from '@mui/icons-material/Mic';
import StopRoundedIcon from '@mui/icons-material/StopRounded';

const kw_title = "The landing page URL of your competitor's website.";
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center"
    },
}));

const OrganicAnalysisScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 650px)");
    const [keyword, setKeyword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;

    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const columns = [
        { field: 'keyword', headerName: 'Keyword', width: 160, sortable: true, renderCell: (params) => (
            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                {params.value}
            </div>
        ) },
        { field: 'position', headerName: 'Position', width: 90, sortable: true },
        { field: 'volume', headerName: 'Volume', width: 130, sortable: true },
        { field: 'cpc', headerName: 'CPC (USD)', width: 90, sortable: true },
        { field: 'competition', headerName: 'Competition', width: 110, sortable: true },
        { field: 'traffic', headerName: 'Traffic (%)', width: 110, sortable: true },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
            setLoading(false);
            const timeout = setTimeout(() => {
                setError("");
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    const addRows = async () => {
        try {
            setRows([]);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                setLoading(true);
                const {data} = await axios.post('/api/organic-analysis', {keyword}, config);
                console.log(data);
                let rowsArray = [];
                for (let i = 0; i < data.length; i++) {
                    const arr = data[i].trends.split(',');
                    let trendArr = [];
                    for (let j = 0; j < arr.length; j++) {
                        trendArr.push({ month: j + 1, value: Number(arr[j]) });
                    }
                    const row = {
                        id: i + 1,
                        keyword: data[i].keyword,
                        position: Number(data[i].position),
                        volume: Number(data[i].volume),
                        cpc: Number(data[i].cpc),
                        competition: Number(data[i].competition),
                        traffic: Number(data[i].traffic),
                    };
                    rowsArray.push(row);
                }
                console.log(rowsArray);
                setRows(rowsArray);
                setKeyword("");
                setLoading(false);
            }
        } catch (error) {
            setError("An error has occurred. Please try again later.");
            console.error(error);
        }
    };

    const addAudioElement = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        
        try {
            const response = await axios.post('/api/stt', formData);
            setKeyword(response.data);
        } catch (err) {
            console.error("There was an error uploading the audio:", err);
        }
    };

    const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder();
    
    useEffect(() => {
        if (!recordingBlob) return;
        addAudioElement(recordingBlob, 'content');
    }, [recordingBlob]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const displayedRows = rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <Box width={isNotMobile ? "90%" : "96%"} 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "22vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow: 5}}
        >
            <Collapse in={error?.length > 0}>
                <Alert severity="error" sx={{mb: 2}}>{error}</Alert>
            </Collapse>
            <form>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center">Competitor Organic Search Analysis</Typography>

                <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                
                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>Enter URL</Typography> 
                <Stack direction="row" spacing={1} alignItems="center" mt={1} mb={2}>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <LightTooltip 
                            PopperProps={{ disablePortal: true }}
                            onClose={handleTooltipClose}
                            placement="top-end"
                            open={open}
                            disableFocusListener
                            disableTouchListener
                            title={kw_title}
                        >
                            <TextField required placeholder="https://www.amazon.com" fullWidth value={keyword} 
                                onChange={(e) => { setKeyword(e.target.value); handleTooltipClose(); }}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Stack direction="row" spacing={.5} alignItems="center">
                                            {!isRecording && <IconButton onClick={startRecording} 
                                                sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                                <MicIcon sx={{color: "background.alt", fontSize: 16}}/>
                                            </IconButton>
                                            }
                                            {isRecording && <IconButton onClick={stopRecording} 
                                                sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                                <StopRoundedIcon sx={{color: "background.alt", fontSize: 16}}/>
                                            </IconButton>
                                            }
                                            <IconButton onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen}>
                                                <HelpIcon sx={{color: "primary.main"}}/>
                                            </IconButton>
                                        </Stack>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </LightTooltip>
                    </ClickAwayListener>
                    <Button variant="contained" size="large" onClick={addRows} disabled={keyword.length === 0 || loading} 
                        endIcon={loading && <CircularProgress size={14}/>}>
                        Search
                    </Button>
                </Stack>
                {isNotMobile ? (
                    <DataGrid rows={rows} columns={columns} pageSize={5} autoHeight />
                ) : (
                    <Stack spacing={2}>
                        {displayedRows.map((row) => (
                            <Card key={row.id} variant="outlined">
                                <CardContent>
                                    <Typography variant="h6">{row.keyword}</Typography>
                                    <Typography variant="body2">Position: {row.position}</Typography>
                                    <Typography variant="body2">Volume: {row.volume}</Typography>
                                    <Typography variant="body2">CPC: ${row.cpc}</Typography>
                                    <Typography variant="body2">Competition: {row.competition}</Typography>
                                    <Typography variant="body2">Traffic: {row.traffic}%</Typography>
                                </CardContent>
                            </Card>
                        ))}
                        <Pagination 
                            count={Math.ceil(rows.length / rowsPerPage)} 
                            page={page} 
                            onChange={handleChangePage} 
                            color="primary"
                        />
                    </Stack>
                )}
            </form>
        </Box>
    )
}

export default OrganicAnalysisScreen;
