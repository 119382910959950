import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./TestScreen.css"
import { Typography } from "@mui/material";
import axios from "axios";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function CheckoutForm({clientSecret, customerId, referenceCode}) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    /*const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );*/

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(async({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded": {
          const subscription = await stripe.subscriptions.create({
            customer: paymentIntent.customer,
            items: [{price: 'price_1Mt0viDNe9PuPsC6HHf2Ua8e'}],//replace with live price
          });
          console.log("sub\n", subscription)
          setMessage("Payment succeeded!");
          break;
        }
          
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("You can always cancel your subscription.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const addressElement = elements.getElement('address');

      const {complete, value} = await addressElement.getValue();

      const res = await axios.get('/api/auth/refresh-token');
      if (res.data) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data}`
            },
        };
        //const ref = Cookies.get('ref');
        await axios.post('/api/stripe/sub', {name: value.name, address: value.address, email: email, customerId: customerId, ref: referenceCode}, config);
      }

      try {
      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `https://piearm.ai/dashboard`,
        },
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
    console.log(err)
  }

  };

  const paymentElementOptions = {
    layout: "tabs",

  }

  return (
    <form id="payment-form" className="form" onSubmit={handleSubmit}>
      <Typography variant="h3" fontWeight="bold" mb={2}> Pay with Card </Typography>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <br/>
      <AddressElement options={{mode: "billing"}}
      onChange={(event) => {
        if (event.complete) {
          // Extract potentially complete address
          const address = event.value.address;
          console.log("address\n", address)
        }
      }}
      />
      <br/>
      {/*<CardElement options={paymentElementOptions}/>*/}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button disabled={isLoading || !stripe || !elements} type="submit" variant="contained" fullWidth={true} disableElevation={true}
        sx={{fontSize: 16, py:1, fontWeight: "bold", textTransform: "none"}}>
          Subscribe {isLoading && <CircularProgress size={20} sx={{ml: 1}}/>}
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}