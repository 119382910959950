import React, { useEffect, useState, useMemo } from 'react';
import {Routes, Route} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import Navbar from './components/common/Navbar';
import HomeScreen  from './components/screens/HomeScreen';
import LoginScreen from './components/screens/LoginScreen';
import RegisterScreen from './components/screens/RegisterScreen';
import SeoScreen from './components/screens/SeoScreen';
import AdCopyScreen from './components/screens/AdCopyScreen';
import PricingScreen from './components/screens/PricingScreen';
import AboutScreen from './components/screens/AboutScreen';
import BlogScreen from './components/screens/BlogScreen';
import ForgotPasswordScreen from './components/screens/ForgotPasswordScreen';
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import VerifyEmailScreen from "./components/screens/VerifyEmailScreen";
import PrCopyScreen from './components/screens/PrCopyScreen';
import KeywordResearchScreen from './components/screens/KeywordResearchScreen';
import DigitalStrategyScreen from './components/screens/DigitalStrategyScreen';
import SocialPlanScreen from './components/screens/SocialPlanScreen';
import SocialCopyScreen from './components/screens/SocialCopyScreen';
import Footer from './components/common/Footer';
import PrivateRoute from './components/routing/PrivateRoute';
import ChatAssistantScreen from './components/screens/ChatAssistantScreen';
import DashboardScreen from './components/screens/DashboardScreen';
import TestScreen from './components/screens/Checkout';
import GoogleAnalytics from './GoogleAnalytics';
import io from 'socket.io-client';
import { SocketContext } from './SocketContext';
import AdminScreen from './components/screens/AdminScreen';
import AdminRoute from './components/routing/AdminRoute';
import BlogPostScreen from './components/screens/BlogPostScreen';
import ShareScreen from './components/screens/ShareScreen';
import SharedArticleScreen from './components/screens/SharedArticleScreen';
import SavedArticleScreen from './components/screens/SavedArticleScreen';
import MarketingEmailScreen from './components/screens/MarketingEmailScreen';
import ProductDescriptionScreen from './components/screens/ProductDescriptionScreen';
import NewsletterCopyScreen from './components/screens/NewsletterCopyScreen';
//import SttScreen from './components/screens/SttScreen';
import SemRushScreen from './components/screens/SemRushScreen';
import EnterpriseScreen from './components/screens/EnterpriseScreen';
import UserRequestScreen from './components/screens/UserRequestScreen';
import PaidAnalysisScreen from './components/screens/PaidAnalysisScreen';
import OrganicAnalysisScreen from './components/screens/OrganicAnalysisScreen';
import FundingPlanScreen from './components/screens/tools/FundingPlan';
import ContentStrategyScreen from './components/screens/tools/ContentStrategyScreen';
import SocialGrowthScreen from './components/screens/tools/SocialGrowth';
import MarketResearchScreen from './components/screens/tools/MarketResearch';
import PartnerDashboard from './components/screens/dashboards/Partner';
import ChatShare from './components/screens/ChatShare';
import PodcastScreen from './components/screens/PodcastScreen';
import ErrorScreen from './components/screens/ErrorScreen';
import ScrollToTop from './ScrollToTop';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INTERCOM_APP_ID = 'fxozg594';

function App() {
  const [socket, setSocket] = useState(null);
  const [themeMode, setThemeMode] = useState('light');

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);

  useEffect(() => {
    if (localStorage.getItem('mode')) {
      setThemeMode(localStorage.getItem('mode'));
    }
    const newSocket = io('https://piearm.ai');//io('http://localhost:8080');//io('https://piearm.ai');
    setSocket(newSocket);
    // Clean up the effect
    return () => newSocket.disconnect();
  }, []);

  //useEffect for themeMode
  useEffect(() => {
    if (themeMode) {
      localStorage.setItem('mode', themeMode);
      //console.log(themeMode)
    }
  }, [themeMode]);


  return (
    socket && <SocketContext.Provider value={socket}>
      <GoogleAnalytics />
      <div className="App">
        <IntercomProvider appId={INTERCOM_APP_ID}>
        <ThemeProvider theme={theme}>

          <CssBaseline />
          <Navbar toggleTheme={toggleTheme} mode={themeMode}/>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<HomeScreen mode={themeMode}/>} />
            <Route exact path="/dashboard" element={<PrivateRoute><DashboardScreen mode={themeMode}/></PrivateRoute>} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/register" element={<RegisterScreen />} />
            <Route exact path="/keyword-research" element={<PrivateRoute><KeywordResearchScreen /></PrivateRoute>} />
            <Route exact path="/seo" element={<PrivateRoute><SeoScreen /></PrivateRoute>} />
            <Route exact path="/ad-copy" element={<PrivateRoute><AdCopyScreen /></PrivateRoute>} />
            <Route exact path="/pr-copy" element={<PrivateRoute><PrCopyScreen /></PrivateRoute>} />
            <Route exact path="/digital-strategy" element={<PrivateRoute><DigitalStrategyScreen/></PrivateRoute>}/>
            <Route exact path="/social-media-plan" element={<PrivateRoute><SocialPlanScreen/></PrivateRoute>}/>
            <Route exact path="/social-media-copy" element={<PrivateRoute><SocialCopyScreen/></PrivateRoute>}/>
            <Route exact path="/pricing" element={<PricingScreen mode={themeMode}/>} />
            <Route exact path="/about" element={<AboutScreen />} />
            <Route exact path="/resources" element={<BlogScreen />} />
            <Route exact path="/ask-piearm" element={<ChatAssistantScreen mode={themeMode}/>} />
            <Route exact path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route exact path="/passwordreset/:resetToken" element={<ResetPasswordScreen/>} />
            <Route exact path="/verify-email/:verifyToken" element={<VerifyEmailScreen/>} />
            <Route exact path="/checkout/:id" element={<PrivateRoute><TestScreen/></PrivateRoute>} />
            <Route exact path="/admin" element={<AdminRoute><AdminScreen/></AdminRoute>} />
            <Route exact path="/resources/:id" element={<BlogPostScreen/>} />
            <Route exact path="/share/:title" element={<ShareScreen mode={themeMode}/>} />
            <Route exact path="/shares" element={<SharedArticleScreen/>} />
            <Route exact path="/saves" element={<SavedArticleScreen/>} />
            <Route exact path="/marketing-email" element={<PrivateRoute><MarketingEmailScreen/></PrivateRoute>} />
            <Route exact path="/product-service-description" element={<PrivateRoute><ProductDescriptionScreen/></PrivateRoute>} />
            <Route exact path="/newsletter-copy" element={<PrivateRoute><NewsletterCopyScreen/></PrivateRoute>} />
            {/*<Route exact path="/stt" element={<PrivateRoute><SttScreen mode={themeMode}/></PrivateRoute>} />*/}
            <Route exact path="/keyword-analysis" element={<AdminRoute><SemRushScreen/></AdminRoute>} />
            <Route exact path="/enterprise" element={<PrivateRoute><EnterpriseScreen/></PrivateRoute>}/>
            <Route exact path="/paid-analysis" element={<PrivateRoute><PaidAnalysisScreen/></PrivateRoute>}/>
            <Route exact path="/organic-analysis" element={<PrivateRoute><OrganicAnalysisScreen/></PrivateRoute>}/>
            <Route exact path="/user-request" element={<PrivateRoute><UserRequestScreen/></PrivateRoute>}/>
            <Route exact path="/funding-plan" element={<PrivateRoute><FundingPlanScreen/></PrivateRoute>}/>
            <Route exact path="/content-strategy" element={<PrivateRoute><ContentStrategyScreen/></PrivateRoute>}/>
            <Route exact path="/social-growth" element={<PrivateRoute><SocialGrowthScreen/></PrivateRoute>}/>
            <Route exact path="/market-research" element={<PrivateRoute><MarketResearchScreen/></PrivateRoute>}/>
            <Route exact path="/partner" element={<PrivateRoute><PartnerDashboard/></PrivateRoute>}/>
            <Route exact path="/podcast" element={<PodcastScreen/>}/>
            <Route exact path="/chat/:id" element={<ChatShare/>}/>
            <Route path="*" element={<ErrorScreen />} />
          </Routes>
          <Footer mode={themeMode}/>
        </ThemeProvider>
        </IntercomProvider>
      </div>
    </SocketContext.Provider>
  );
}

export default App;
//export default withAITracking(reactPlugin, App);