import { useEffect, useState } from "react";
import { useParams, useNavigate} from "react-router-dom";
import axios from "axios";
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField } from '@mui/material';
import ThemedButton from "../common/ThemedButton";

const ResetPasswordScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const {resetToken}  = useParams();

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords don't match");
        }

        if (password.length < 6) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Password must be at least 6 characters");
        }

        try {
            const { data } = await axios.put(
                `/api/auth/password-reset/${resetToken}`,
                {
                    password,
                },
                config
            );
                console.log("data", data)
            setSuccess(data.data);
        } catch (error) {
            if (error.response.data.error) {
                setError(error.response.data.error);
            } else if (error.message) {
            setError(error.message);
            }
        }
    };

    //useEffect for success, if success.length > 0 then navigate to login after 5 seconds
    useEffect(() => {
        if (success.length > 0) {
            navigate('/login')
        }
    }, [success]);

    //useEffect for error
    useEffect(() => {
        if (error.length > 0) {
            setTimeout(() => {
                setError("");
            }, 12000);
        }
    }, [error]);

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "12vh" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error.length > 0}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={resetPasswordHandler}>
                <Collapse in={success.length > 0}>
                    <Alert severity="success" sx={{mb:2}}>{success}</Alert>
                </Collapse>
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center" color="primary">Reset Password</Typography>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Confirm Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} required fullWidth value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>

                <ThemedButton w="100%" type="submit" text="Reset Password"/>
                
            </form>

        </Box>
    );
};

export default ResetPasswordScreen;