import React, {useState} from 'react';
import { FormControl, FormHelperText, useMediaQuery} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Tone = ({tone, setTone, }) => {
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [ selected, setSelected ] = useState(false);

    const handleChange = (e) => {
        setTone(e.target.value);
        if (e.target.value)
            setSelected(true);  // reset error state when a selection is made
    }

    return (
        <FormControl fullWidth sx={{ mt: isNotMobile ? 0.5 : 0, mb: 3, }}>
            <RadioGroup
                row
                aria-labelledby="tone-radio-buttons-group-label"
                name="tone-radio-buttons-group"
                onChange={handleChange}
                value={tone}
            >
                <FormControlLabel value="professional" control={<Radio size="small" />} label="Professional" />
                <FormControlLabel value="friendly" control={<Radio size="small" />} label="Friendly" />
                <FormControlLabel value="persuasive" control={<Radio size="small" />} label="Persuasive" />
                <FormControlLabel value="neutral" control={<Radio size="small" />} label="Neutral" />
            </RadioGroup>
            {(!selected && tone.length === 0) && <FormHelperText>This field is required</FormHelperText>}
        </FormControl>
    )
}

export default Tone;