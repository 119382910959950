import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import { Button, CircularProgress } from '@mui/material';
const ThemedButton = ({ fSize=16, w=220, bColor="primary.main", bool, spinner, bRadius=10, color, text, pad=1.4}) => {
    return (
    <Button variant="contained" textDecoration="none" type="submit" disableElevation={true} disabled={bool}
        sx={{textTransform: 'none', 
            color: color && color,
            borderRadius: bRadius, 
            backgroundColor: bColor, 
            fontSize: fSize, 
            width: w, 
            py: pad, 
            fontWeight: "bold"
        }} 
        endIcon={
          spinner && <CircularProgress size={18}/>
        }
        >
        {text}
    </Button>
    );
};

export default ThemedButton;
