import React from 'react' ;
import { Box, Stack, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ThemedButton from '../common/ThemedButton';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import right from '../assets/right.svg';
import left from '../assets/left.svg';
import { Helmet } from 'react-helmet-async';
//import Cookies from 'js-cookie';

const LoginScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const config = {
        headers: { "Content-Type": "application/json" },
    }

    const loginHandler = async (e) => { 
        e.preventDefault();

        try {
            const { data } = await axios.post("/api/auth/login", {email, password}, config);
            if (data.token.accessToken) {
                localStorage.setItem("authToken", true);
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${data.token.accessToken}`
                    },
                };
                const usr = await axios.get(`/api/auth/subscription`, config);
                if (usr.data.subscription) {
                    localStorage.setItem("sub", true);
                }
                let nav = "/dashboard";
                await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                navigate(nav);
                //window.location.reload();
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            }
        }
    }

    const [ user, setUser ] = useState("");

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token);},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post("/api/auth/google-login", {google_email, verified_email}, config);
                        if (data.token.accessToken) {
                            localStorage.setItem("authToken", true);
                            const config = {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${data.token.accessToken}`
                                },
                            };
                            const usr = await axios.get(`/api/auth/subscription`, config);
                            if (usr.data.subscription) {
                                localStorage.setItem("sub", true);
                            } else {
                                localStorage.removeItem("sub");
                            }
                            let nav = "/dashboard";
                            await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                            await axios.get("/api/auth/partner", config).then(res => {localStorage.setItem("partner", res.data.partner); nav="/partner" });
                            navigate(nav);
                            //window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                        } else {
                            setError(err.message);
                        }
                    });
            }
        },
        [ user ]
    );

    //useEffect for error
    useEffect(() => {
        if (error) {
            if (!error.includes("verify")) {
                setTimeout(() => {
                    setError("");
                }, 5000);
            }
        }
    }, [error]);

    return (
        <Stack direction="row" width="100%" mt={isNotMobile ? '25vh' : 12}
        mb={isNotMobile ? 14 : 18}>
            <Helmet>
                <title> Sign Up | PIEARM™ </title>
                <meta property="descriptions" content="Sign in page for PIEARM™" />
                <meta property="og:title" content={`Sign In | PIEARM™`} />
                <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                <meta property="og:description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds." />
                <meta property="og:url" content="https://piearm.ai" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Sign Up | PIEARM™`} />
                <meta name="twitter:description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds." />
                <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
            </Helmet>
            {isNotMobile && <img src={left} alt="left" style={{width: "auto", height: "60vh"}}/>}
            <Box flex={1}></Box>
            <Box width={isNotMobile ? "25%" : "80%" } >
                <Collapse in={error} sx={{cursor: "pointer"}} onClick={()=>setError("")}>
                { error.includes("verify") ? 
                    <Alert severity="info" sx={{mb:2}} onClick={()=>axios.post('/api/auth/resend-verification', {email: email})}>{error}. Click <b>here</b> to resend verification email</Alert> :
                    <Alert severity="error" sx={{mb:2}}>{error}</Alert>
                }
                </Collapse>
                <form onSubmit={loginHandler}>
                    <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center">Sign In</Typography>
                    <Button variant="outlined" size="large" fullWidth sx={{py: 0.6, borderRadius: 10}}
                        startIcon={<GoogleIcon/>}
                        onClick={() => googleLogin()}
                    >
                        Sign in with Google
                    </Button>

                    <Divider sx={{mt:3, mb:2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>

                    <TextField size="small" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:2}} placeholder="Email" required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                    <TextField size="small" type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} placeholder="Password" required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>
                    { /* <Button fullWidth variant="contained" type="submit" size="large" sx={{color:"white", my:2}}>Log in</Button> */ }
                    <ThemedButton w="100%" type="submit" text="Log in" pad={0.6}/>

                    <Divider sx={{my:4}}></Divider>
                </form>
                <Typography mt={2} textAlign="center">Don't have an account? <ReactRouterLink style={{color: theme.palette.primary.main, textDecoration: 'underline' }} to="/register"><b style={{':hover': { textDecoration: 'underline' }}}>Sign up</b></ReactRouterLink></Typography>
                <Typography mt={2} textAlign="center"> <ReactRouterLink to="/forgot-password" style={{color:theme.palette.primary.main, textDecoration: 'underline' }}>Forgot your password?</ReactRouterLink></Typography>

            </Box>
            <Box flex={1}></Box>
            {isNotMobile && <img src={right} alt="right" style={{width: "auto", height: "60vh"}}/>}
        </Stack>
    )
}

export default LoginScreen;