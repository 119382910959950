import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SubDropdown from './SubDropdown';
import { Modal, Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField } from '@mui/material';

import ThemedButton from '../common/ThemedButton';

const AdminRegisterModal = ({ open, handleClose}) => {
    const [error, setError] = useState("");
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [plan, setPlan] = useState("free");

    //useEffect for error
    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError("");
            }, 10000);
            return () => clearTimeout(timeout);
        }
    }, [error]);


    const registerHandler = async (e) => { 
        e.preventDefault();

        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data) {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.post("/api/auth/admin-register", {email, password, plan}, config);
                setEmail("");
                setPassword("");
                setPlan("");
                handleClose();
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.message) {
                setError(err.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    return (
        <Box>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            pb={"2rem"}
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "2rem auto" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={registerHandler}>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:2}} required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:2}} required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Subscription Tier</Typography>
                <SubDropdown plan={plan} setPlan={setPlan} margB={4} size="large"/>

                <ThemedButton w="100%" type="submit" text="Create User"/>

            </form>

        </Box>
        </Modal>
    </Box>
    );
}

export default AdminRegisterModal;