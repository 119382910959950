import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, Collapse, Alert, Stack, Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import logo from '../assets/logo512.png';
import Markdown from "react-markdown";

const ChatShare = () => {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [systemMessages, setSystemMessages] = useState([]);
    const [userMessages, setUserMessages] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        async function fetchHistories() {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    const { data } = await axios.get(`/api/history/get/${id}`, config);
                    if (data.history) {
                        const regex = /\*-\*/;
                        const arr = data.history.split(regex);
                        const arr1 = [];
                        const arr2 = [];
                        for (let i = 0; i < arr.length; i++) {
                            if (i % 2 === 0) {
                                arr1.push(arr[i]); // add even-indexed items to arr1
                            } else {
                                arr2.push(arr[i]); // add odd-indexed items to arr2
                            }
                        }
                        setUserMessages(arr1.filter(element => element !== ''));
                        setSystemMessages(arr2.filter(element => element !== ''));
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchHistories();
    }, [id]);

    //useEffect for error
    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
            setLoading(false);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    return (
        <Box width={isNotMobile ? "60%" : "90%"} 
            p="2rem" 
            m="2rem auto"
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "40vh" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow: 5}}
        >
            <Collapse in={error.length > 0}>
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            </Collapse>

            {(id &&
            <Box width="100%" mb={isNotMobile ? 3 : 2}>
                <Typography variant={isNotMobile ? "h4" : "h5"} fontWeight="bold"> Message History </Typography>
                {userMessages.map((message, index) => (
                    <Stack spacing={2} mt={2} key={index}>
                        <Stack direction="row" py={0} spacing={2} alignItems="center">
                            <Card sx={{ py: 0, px: 2, border: 1, boxShadow: 2, borderColor: "neutral.dark", borderRadius: 3, bgcolor: "#e8eef6", width: "100%" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}><Typography>{userMessages[userMessages.length - index - 1]}</Typography></pre>
                            </Card>
                            <Avatar alt="Chat Assistant" sx={{ bgcolor: "#eef5ff", width: 48, height: 48 }}>
                                <PersonIcon sx={{ color: "primary.main", fontSize: 25 }} />
                            </Avatar>
                        </Stack>
                        <Stack direction="row" py={0} spacing={2} alignItems="center">
                            <Avatar alt="Chat Assistant" src={logo} sx={{ bgcolor: "#eef5ff", width: 48, height: 48, p: 1 }} />
                            <Card sx={{ py: 0, px: 2, border: 1, boxShadow: 2, borderColor: "neutral.dark", borderRadius: 3, bgcolor: "neutral.light", width: "100%" }}>
                                {loading && index === 0 ?  <pre style={{ whiteSpace: "pre-wrap" }}><Typography>Loading...</Typography></pre>
                                : 
                                (systemMessages[systemMessages.length - index - 1].includes('https') && systemMessages[systemMessages.length - index - 1].includes('blob') ? 
                                    <pre style={{ whiteSpace: "pre-wrap" }}><img src={systemMessages[systemMessages.length - index - 1]} style={{width:'40vw'}} /></pre>
                                     :  <div style={{minHeight: '48px', alignContent: "center"}}>
                                    <Markdown>{systemMessages[systemMessages.length - index - 1]}</Markdown>
                                </div> )
                                }
                            </Card>
                        </Stack>
                    </Stack>
                ))}
            </Box>
            )}
        </Box>
    )
}

export default ChatShare;
