import React from 'react';
import { Modal, Box, Typography, Divider, Stack, Tooltip, Button } from '@mui/material';
import axios from 'axios';
import { saveAs } from "file-saver";

import ShareIcons from './ShareIcons';

const ShareModal = ({ open, handleClose, content, kw, id, title}) => {
    

    const exportDocx = async () => {
        try {
            axios.post('/createDocx', { content: content }, { responseType: 'blob' })
                .then(function (response) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                    saveAs(blob, 'article.docx');
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
          console.log(error);
          //setError("Error exporting DOCX. Please Try again.");
        }
      }

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
        >
            <Stack maxWidth={500} backgroundColor="background.alt" border = "1px solid #000" 
            justifyContent="center"
            sx={{boxShadow:2, p: 2, mx: 10, borderRadius: 2}}>
                <Typography fontWeight="bold" textAlign="left">Share this article:</Typography>
                <ShareIcons id={id} kw={kw} title={title}/>
                <Divider sx={{mt:1, mb:2}}>OR</Divider>
                <Button variant="contained" onClick={exportDocx}>Export as DOCX</Button>
            </Stack>
        </Modal>
    );
}

export default ShareModal;