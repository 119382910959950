import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery, CircularProgress, ClickAwayListener, IconButton,
    Collapse, Alert, TextField, Button, Divider, Stack, Paper, Tooltip, InputAdornment} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
    Chart,
    AreaSeries,
    LineSeries,
  } from '@devexpress/dx-react-chart-material-ui';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import MicIcon from '@mui/icons-material/Mic';
import StopRoundedIcon from '@mui/icons-material/StopRounded';

const kw_title = "Keywords are specific words or phrases that are used to identify a topic."
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center"
    },
}));

const SemRushScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    //const navigate = useNavigate();
    const [keyword, setKeyword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    
      const columns = [
        { field: 'keyword', headerName: 'Keyword', width: 180, sortable: true },
        { field: 'intent', headerName: 'Intent', width: 220, sortable: false },
        { field: 'volume', headerName: 'Volume', width: 130, sortable: true },
        { field: 'trend', headerName: 'Trend', width: 130, sortable: false, renderCell: (params) => (
            <Paper sx={{width: '100%', height: '100%', boxShadow: 0, p: 0, justifyContent: "left"}}>
                <Chart
                data={params.value}
                className={`test-chart`}
                height={50}
                >
                    <LineSeries
                        name="Trends"
                        valueField="value"
                        argumentField="month"
                    />
                    <AreaSeries
                        name="Trends"
                        valueField="value"
                        argumentField="month"
                        color="lightblue"
                    />
                </Chart></Paper>
          ) },
        { field: 'kd', headerName: 'KD %', width: 70, sortable: true },
        { field: 'cpc', headerName: 'CPC (USD)', width: 90, sortable: true },
        { field: 'com', headerName: 'Com.', width: 70, sortable: true },
        { field: 'results', headerName: 'Results', width: 130, sortable: true },
      ];



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //useEffect for error
    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
            setLoading(false)
            //clear after five seconds
            const timeout = setTimeout(() => {
                setError("");
            }
            , 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

        
    const addRow = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                setLoading(true)
                const {data} = await axios.post('/api/semrush', {keyword}, config)
                //split data.trend by comma
                const arr = data.trend.split(',');
                //loop through arr and convert to int and add to dict
                let trendArr = []
                for (let i=0; i < arr.length; i++) {
                    trendArr.push({month: i+1, value: Number(arr[i])})
                }
                //replace data.intent values: 0 - Commercial, 1 - Informational, 2 - Navigational, 3 - Transactional)
                let intentVals = data.intent.replace('0', 'Commercial').replace('1', 'Informational').replace('2', 'Navigational').replace('3', 'Transactional').replace(',',', ')
                setRows([
                    ...rows,
                    {
                    id: rows.length + 1,
                    keyword: keyword,
                    intent: intentVals,
                    volume: Number(data.volume),
                    trend: trendArr,
                    kd: Number(data.kd),
                    cpc: Number(data.cpc),
                    com: Number(data.com),
                    results: Number(data.results),
                    },
                ]);
                setKeyword("");
                setLoading(false);
            }
        } catch (error) {
            setError("An error has occurred. Please try again later.")
        }
    };

    const addAudioElement = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        
        try {
            const response = await axios.post('/api/stt', formData);
            setKeyword(response.data)
        } catch (err) {
            console.error("There was an error uploading the audio:", err);
        }
    };

    const { startRecording, stopRecording, recordingBlob, isRecording} = useAudioRecorder();
    
    useEffect(() => {
        if (!recordingBlob) return;
        addAudioElement(recordingBlob, 'content')
    // recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recordingBlob]);

    return (
        <Box width={isNotMobile ? "90%" : "96%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "22vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form >
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center">Keyword Analysis</Typography>

                <Divider sx={{ mt:1, mb:2}}></Divider>
                
                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>Enter Keyword/Phrase</Typography> 
                <Stack direction="row" spacing={1} alignItems="center" mt={1} mb={2}>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <LightTooltip 
                        PopperProps={{ disablePortal: true,}}
                        onClose={handleTooltipClose}
                        placement="top-end"
                        open={open}
                        disableFocusListener
                        disableTouchListener
                        title={kw_title}
                        >
                            <TextField required placeholder="AI copywriting" fullWidth value={keyword} 
                            onChange={(e) => {setKeyword(e.target.value); handleTooltipClose();}}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <Stack direction="row" spacing={.5} alignItems="center">
                                        {!isRecording && <IconButton onClick={startRecording} 
                                        sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                            <MicIcon sx={{color: "background.alt", fontSize: 16}}/>
                                        </IconButton>
                                        }
                                        {isRecording && <IconButton onClick={stopRecording} 
                                        sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                            <StopRoundedIcon sx={{color: "background.alt", fontSize: 16}}/>
                                        </IconButton>
                                        }
                                    
                                        <IconButton onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen}>
                                            <HelpIcon sx={{color: "primary.main"}}/>
                                        </IconButton>
                                    </Stack>
                                </InputAdornment>
                                ),
                            }}
                            />
                        </LightTooltip>
                    </ClickAwayListener>
                    <Button variant="contained" size="large" onClick={addRow} disabled={keyword.length === 0 || loading} 
                    endIcon={loading && <CircularProgress size={14}/>}>
                        Search
                    </Button>
                </Stack>
                <DataGrid rows={rows} columns={columns} pageSize={5} />
                
            </form>
        </Box>
    )
}

export default SemRushScreen;