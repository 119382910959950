import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Divider, useMediaQuery, Avatar } from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
  } from "react-share";
import LoadingSpinner from '../common/LoadingSpinner';

const title = "Check out this article on Piearm.ai!";

function BlogPostScreen() {
    const rawId = useParams();
    const mobile = useMediaQuery("(max-width: 1100px)");
    const [posts, setPosts] = useState([]);
    const [showScroll, setShowScroll] = useState(false);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState();

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 100) {
        setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 100) {
        setShowScroll(false);
        }
    };

    useEffect(() => {
        if (rawId.id) {
            setId(rawId.id)
            //setId(rawId.id.split("-")[rawId.id.split("-").length - 1]);
        }
        //scroll to top of page
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [showScroll]);

    const fetchComments = (id) => {
        if (id === undefined) return;
        fetch(`https://blog.piearm.ai/wp-json/wp/v2/comments?post=${id}`)
            .then(response => response.json())
            .then(setComments)
            .catch(console.error);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://blog.piearm.ai/wp-json/wp/v2/posts');
                const data = await response.json();
                const postsWithMediaPromises = data.map(async post => {
                const mediaResponse = await fetch(`https://blog.piearm.ai/wp-json/wp/v2/media/${post.featured_media}`);
                const media = await mediaResponse.json();
                return {
                    ...post,
                    featuredImageUrl: media.source_url,
                };
                });
                const postsWithMedia = await Promise.all(postsWithMediaPromises);
                setPosts(postsWithMedia);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
            };
        
        fetchData().then( () => fetchComments(id));
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const copyToClipboard = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    if (loading) {
        return <Box p={30}><LoadingSpinner/></Box>;  // you can replace this with your own custom loader or a skeleton screen
    }

    function removeNonLettersAndSpaces(inputString) {
        return inputString.replace(/[^a-zA-Z\s]/g, '');
    }

  return (
    <Box sx={{mt: "120px",mb: "100px", display:"flex", justifyContent:"center"}}>
      {posts.map(post => (removeNonLettersAndSpaces(post.title.rendered).replace(/ /g, "-") === id &&
        <Box position="relative">
            <Box maxWidth="800px" key={post.id} px={mobile ? 3 : 0}>
                {post.featuredImageUrl && <img src={post.featuredImageUrl} alt={`${post.id}`} width="100%" height="auto" style={{borderRadius: 4, marginBottom: "20px"}}/>}
                <Typography fontSize={mobile ? "20px" : "30px"} textAlign="center" fontWeight="bold" dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                <Typography fontSize={mobile ? "12px" : "16.77px"} textAlign="center" color="gray" mt={1.2}>{formatDate(post.date)}</Typography>
                <Typography fontSize={mobile ? "14px" : "20.7px"} dangerouslySetInnerHTML={{__html: post.content.rendered}} />
                <Typography fontSize={mobile ? "12px" : "16px"} fontWeight="bold">Share this entry:</Typography>
            </Box>
            <Box width={mobile ? "30px" : "50px"} height={mobile ? "30px" : "50px"} border="1px solid grey" borderRadius="2px" position="fixed" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                bgcolor="#494949" display="flex" justifyContent="center" alignItems="center" right={mobile ? "30px" : "50px"} bottom={mobile ? "60px" : "100px"}
                sx={{ 
                    transition: "0.4s",
                    cursor: "pointer",
                    visibility: showScroll ? 'visible' : 'hidden',
                    opacity: showScroll ? 1 : 0,
                    '&:hover': {
                        bgcolor: "white",
                        '& svg': {
                            color: "royalBlue",
                        },
                        border: '1px solid lightgray'
                    }
                }}>
                    <KeyboardArrowUpRoundedIcon sx={{color:"white"}}/>
            </Box>

            <Stack direction="row" spacing={2} alignItems="top" mt={2}>
                <Tooltip title="Facebook" placement="top" arrow={true}>
                    <FacebookShareButton url={`https://piearm.ai/blog/${post.id}`} quote={title}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </Tooltip>

                <Tooltip title="Twitter" placement="top" arrow={true}>
                    <TwitterShareButton url={`https://piearm.ai/blog/${post.id}`} title={title}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </Tooltip>

                {/*<Tooltip title="Threads" placement="top" arrow={true}>
                    <Box component={ReactRouterLink} to={`https://piearm.ai/blog/${post.id}`} target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/threads.webp`} alt="Threads Share" width="31px" height="auto" />
                    </Box>
                </Tooltip>*/}

                <Tooltip title="WhatsApp" placement="top" arrow={true}>
                    <WhatsappShareButton url={`https://piearm.ai/blog/${post.id}`} title={title} separator=":: ">
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </Tooltip>

                <Tooltip title="Pinterest" placement="top" arrow={true}>
                    <PinterestShareButton url={`https://piearm.ai/blog/${post.id}`} media={`https://piearm.ai/blog/${post.id}`} description={title}>
                        <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                </Tooltip>

                <Tooltip title="Reddit" placement="top" arrow={true}>
                    <RedditShareButton url={`https://piearm.ai/blog/${post.id}`} title={title}>
                        <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                </Tooltip>

                <Tooltip title="LinkedIn" placement="top" arrow={true}>
                    <LinkedinShareButton url={`https://piearm.ai/blog/${post.id}`}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </Tooltip>

                <Tooltip title="Email" placement="top" arrow={true}>
                    <EmailShareButton url={`https://piearm.ai/resources/${post.id}`} subject={title} body={`${post.title.rendered}\n\n`}>
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                </Tooltip>

                <Tooltip title="Copy" placement="top" arrow={true}>
                    <Avatar sx={{ width: 31, height: 31, ":hover": {cursor: "pointer"} }} onClick={copyToClipboard(`https://piearm.ai/resources/${post.id}`)}>
                        <AddLinkRoundedIcon sx={{fontSize: "20px"}}/>
                    </Avatar>
                </Tooltip>
            </Stack>
            <Stack alignItems="center" mt="8vh">
                <Box width="100%">
                <Divider sx={{mb: 1}}>
                    <Avatar sx={{ bgcolor: "primary.main", width: 60, height: 60, fontWeight: 400, fontSize: 24 }}>
                        {comments.length}
                    </Avatar>
                    </Divider>
                </Box>
                <Typography color="gray" fontSize={12.5}>REPLIES</Typography>
            </Stack>
            {comments.map(comment => (
                <Box key={comment.id}>
                    <Typography fontWeight="bold">{comment.author_name}</Typography>
                    <Typography>{comment.date}</Typography>
                    <Typography dangerouslySetInnerHTML={{__html: comment.content.rendered}} />
                </Box>
            ))}
        </Box>
    ))}
    </Box>
  );
}

export default BlogPostScreen;
