import React from 'react';
import { Box, Typography, useMediaQuery, Collapse, Alert, Stack, } from '@mui/material';


const AboutScreen = () => {

    return (
        <Stack p={3} spacing={4} sx={{display:"flex", alignItems: "center"}}>
            <Box width={580}>
                <Typography fontWeight={300} fontSize={60} mb={1} align="center" color="textPrimary">
                About Us
                </Typography>
                <Typography variant="h4" align="center" color="textSecondary" component="p">
                This is a dummy About Us Page. Fill it out with the content you need when you are ready.
                </Typography>
            </Box>
        </Stack>
    )
}

export default AboutScreen;