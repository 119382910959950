import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Stack, Divider, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import ThemedButton from '../common/ThemedButton';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import right from '../assets/right.svg';
import left from '../assets/left.svg';
import { Helmet } from 'react-helmet-async';

const RegisterScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const config = {
        headers: { "Content-Type": "application/json" },
    }

    const registerHandler = async (e) => { 
        e.preventDefault();

        try {
            await axios.post("/api/auth/register", {email, password}, config);
            navigate("/login");

        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.message) {
                setError(err.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    const [ user, setUser ] = useState("");
    const googleRegister = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token); console.log(codeResponse);},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                console.log(user)
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const name = res.data.name;
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post("/api/auth/google-register", {name, google_email, verified_email}, config);
                        console.log(data)
                        if (data.token.accessToken) {
                            localStorage.setItem("authToken", true);
                            navigate("/");
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                            setTimeout(() => {setError("");}, 4000);
                        } else {
                            setError(err.message);
                            setTimeout(() => {setError("");} , 4000);
                        }
                    });
            }
        },
        [ user ]
    );

    return (
        <Stack direction="row" width="100%" mt={isNotMobile ? '25vh' : 12}
        mb={isNotMobile ? 18 : 20}>
             <Helmet>
                <title> Sign Up | PIEARM™ </title>
                <meta property="description" content="Sign up page for PIEARM™" />
                <meta property="og:title" content={`Sign Up | PIEARM™`} />
                <meta property="og:image" name="facebook:image" content="https://i.imgur.com/BTR898f.png" />
                <meta property="og:description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds." />
                <meta property="og:url" content="https://piearm.ai" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Sign Up | PIEARM™`} />
                <meta name="twitter:description" content="PIEARM™ is a platform that leverages AI to provide a suite of digital marketing tools to analyze, process and generate marketing copy in seconds." />
                <meta name="twitter:image" content="https://i.imgur.com/Qq5GXUG.png" />
            </Helmet>
            {isNotMobile && <img src={left} alt="left" style={{width: "auto", height: "60vh"}}/>}
            <Box flex={1}></Box>
            <Box width={isNotMobile ? "25%" : "80%" } >
                <Collapse in={error}>
                    <Alert severity="error" sx={{mb:2}}>{error}</Alert>
                </Collapse>
                <form onSubmit={registerHandler}>
                    <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center">Sign Up</Typography>

                    <Button variant="outlined" size="large" fullWidth sx={{borderRadius: 10, py: 0.6}}
                        startIcon={<GoogleIcon/>}
                        onClick={() => googleRegister()}
                    >
                        Sign up with Google
                    </Button>

                    <Divider sx={{mt:3, mb:2, color: "primary.main"}}> <Typography fontWeight="bold" color="primary">OR</Typography> </Divider>

                    <TextField size="small" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:2}} required fullWidth value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>

                    <TextField size="small" type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} required fullWidth placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>

                    <ThemedButton w="100%" type="submit" text="Sign Up" pad={0.6}/>
                    
                    <Divider sx={{my:4}}></Divider>

                </form>


                <Typography textAlign="center">Already have an account? <ReactRouterLink style={{color: theme.palette.primary.main, textDecoration: "underline"}} to="/login"><b>Sign in</b></ReactRouterLink></Typography>
            </Box>
            <Box flex={1}></Box>
            {isNotMobile && <img src={right} alt="right" style={{width: "auto", height: "60vh"}}/>}
        </Stack>
        
    )
}

export default RegisterScreen;